export const timelineData = [
  {
    default: 'Alle Informationen und Services',
    image: '/assets/img/default_info_icon.svg',
    index: 0,
    title: 'myGRIMME_timeline_bar_first',
  },
  {
    default: 'Automatische Job- Dokumentation​',
    image: '/assets/img/default_navigation_icon.svg',
    index: 1,
    title: 'myGRIMME_timeline_bar_second',
  },
  {
    default: 'GRIMME Original-Teile finden & bestellen',
    image: '/assets/img/default_cart_icon.svg',
    index: 2,
    title: 'myGRIMME_timeline_bar_third',
  },
];
