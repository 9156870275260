import { environment } from '../../environments';

export const login = () => {
  const queryString = new URLSearchParams({
    callbackUrl: window.location.href,
    useGrid: 'true',
  }).toString();

  const loginUrl = new URL(`login?${queryString}`, environment.authServer);
  window.location.assign(loginUrl);
};
