import {
  Dealer,
  DealersResponse,
  MachineConfiguration,
  SerialMachineData,
  SortCropsBody,
} from '@mygrimme/types';
import { CropFormData, CropVariety } from '~/pages-components/crops/utils';
import { GrimmeApiCrop } from '~/pages-components/crops/utils/types';
import { GetWearPartsResponse } from '~/pages-components/machine-details/hooks/types';
import { createApiUrl } from './ApiQuery';

export interface ErrorBody {
  details?: Record<string, string>;
  message?: string;
}

export class APIError extends Error {
  details: Record<string, string>;

  constructor(response: ErrorBody) {
    super(response.message);
    this.message = response.message ?? '';
    this.details = response.details ?? {};
  }
}

export class NetworkError extends Error {
  constructor(response: unknown) {
    super('NetworkError');
    this.message = 'NetworkError';
  }
}

export interface CallApiProps<Body> {
  accessToken?: string | undefined;
  body?: Body;
  endpoint: ReturnType<typeof createApiUrl>;
  id: ApiCallsFunctions;
  language?: string;
  method?: 'get' | 'post' | 'delete' | 'put';
  responseType?: 'json' | 'blob';
}

export type ApiCallsFunctions = keyof ApiCalls;

export type ApiCalls = {
  createCrop: (
    accessToken: string | undefined,
    body: CropFormData,
  ) => Promise<CropVariety | undefined>;

  deleteCrop: (
    accessToken: string,
    cropType: number,
    cropVarietyId: number,
  ) => Promise<CropVariety | undefined>;

  downloadDocument: (
    docId: string,
    encodedDocumentHash: string,
    filename: string | undefined,
    accessToken: string,
  ) => Promise<void>;

  getCCIAuthToken: (
    sessionCode: string,
    accessToken: string,
  ) => Promise<CCITokenResponse | undefined>;

  getCrops: (
    accessToken: string,
    bussinessRelationId: string,
  ) => Promise<GrimmeApiCrop[]>;

  getDealerById: (
    id: string,
    accessToken: string,
  ) => Promise<Dealer | undefined>;

  getDealersV2: (
    countryCode: string | undefined,
    language: string | undefined,
    accessToken: string,
  ) => Promise<DealersResponse>;

  getGeolocation: (language: string) => Promise<Geolocation | undefined>;

  getMachineBySerial: (
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<SerialMachineData>;

  getMachineConfigurationBySerial: (
    serial: string,
    language: string,
    accessToken: string,
  ) => Promise<MachineConfigurationObject>;

  getTelemetryData: (
    serials: string[],
    accessToken: string,
    country?: string,
  ) => Promise<TelemetryDataBySerial | undefined>;

  getWearParts: (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => Promise<GetWearPartsResponse | undefined>;

  getWearPartsExcel: (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => Promise<Blob | undefined>;

  sortCrop: (
    accesToken: string,
    body: SortCropsBody,
    cropType: number,
  ) => Promise<CropVariety[] | undefined>;

  updateCrop: (
    accessToken: string | undefined,
    body: CropFormData,
  ) => Promise<CropVariety | undefined>;
};

export type MachineConfigurationObject = {
  configuration: MachineConfiguration[];
  serial: string;
};

// TODO: move to types
export interface CCITokenResponse {
  jwt: string;
  message: string;
  state: string;
}

export interface GridApiError {
  message?: string;
  response?: {
    data?: {
      error?: string;
    };
  };
}

export enum TelemetryOnlineStatus {
  OFFLINE = 0,
  ONLINE = 1,
  ERROR = 2,
}

export interface TelemetryData {
  engine_time?: number;
  engine_time_unit?: string;
  hectares?: number;
  hectares_unit?: string;
  lastOnlineAt?: string;
  status?: TelemetryOnlineStatus;
  timestamp?: string;
}

export type TelemetryDataBySerial = Record<string, TelemetryData>;
