import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { downloadDocument } from '../utils/ApiQuery';
import { loadingStatus, LocalMachine } from './utils/utils';

export const MACHINES_FEATURE_KEY = 'machines';
export interface MachinesState extends EntityState<LocalMachine, string> {
  documentDownloadStatus: loadingStatus;
  downloadDocId?: string;
  editMachineNameStatus: loadingStatus;
  error?: string;
  loadingStatus: loadingStatus;
}

export const machinesAdapter = createEntityAdapter<LocalMachine, string>({
  selectId: (entity) => entity.SerialNumber as string,
});

export const downloadSingleDocument = createAsyncThunk(
  'machines/documents/download',
  async (args: {
    accessToken: string;
    docId: string;
    encodedDocumentHash: string;
    filename: string;
  }) => {
    return downloadDocument(
      args.docId,
      args.encodedDocumentHash,
      args.filename,
      args.accessToken,
    );
  },
);

export const initialMachinesState: MachinesState =
  machinesAdapter.getInitialState({
    documentDownloadStatus: loadingStatus.NOT_LOADED,
    downloadDocId: undefined,
    editMachineNameStatus: loadingStatus.NOT_LOADED,
    error: undefined,
    loadingStatus: loadingStatus.NOT_LOADED,
  });

export const machinesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(
        downloadSingleDocument.pending,
        (state: MachinesState, action) => {
          state.documentDownloadStatus = loadingStatus.LOADING;
          state.downloadDocId = action?.meta?.arg?.docId;
        },
      )
      .addCase(downloadSingleDocument.fulfilled, (state: MachinesState) => {
        state.documentDownloadStatus = loadingStatus.LOADED;
        state.downloadDocId = undefined;
      })
      .addCase(
        downloadSingleDocument.rejected,
        (state: MachinesState, action) => {
          state.documentDownloadStatus = loadingStatus.ERROR;
          state.downloadDocId = undefined;
          state.error = action.error.message;
        },
      );
  },
  initialState: initialMachinesState,
  name: MACHINES_FEATURE_KEY,
  reducers: {
    add: machinesAdapter.addOne,
    remove: machinesAdapter.removeOne,
    resetEditMachineNameStatus: (state) => {
      state.loadingStatus = loadingStatus.NOT_LOADED;
    },
    // ...
  },
});

export const machinesReducer = machinesSlice.reducer;

export const machinesActions = machinesSlice.actions;

export const getMachinesState = (rootState: {
  [MACHINES_FEATURE_KEY]: MachinesState;
}): MachinesState => rootState[MACHINES_FEATURE_KEY];

export const areMachinesDifferentFromProfile = (
  machineIdsFromProfile: string[],
) =>
  createSelector(getMachinesState, (state) =>
    machineIdsFromProfile.some((mId) => !state.ids.includes(mId)),
  );

export const isDocumentDownloading = createSelector(
  getMachinesState,
  (state) => state.documentDownloadStatus === loadingStatus.LOADING,
);

export const selectDownloadingDocumentId = createSelector(
  getMachinesState,
  (state) => state.downloadDocId,
);

export const selectEditMachineNameStatus = createSelector(
  getMachinesState,
  (state) => state.editMachineNameStatus,
);
