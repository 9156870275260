import { UserStatus } from '~/pages-components/crops/utils/utils';
import { LinkIndex } from '~/pages-components/utils';

export interface DashboardContextValue {
  handleClick: (event: string, index: LinkIndex) => void;
  onShopDialogCancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onShopDialogClose: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton',
  ) => void;
  onShopDialogConfirm: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  setShowRegistrationFlowCompletedDialog: (open: boolean) => void;
  setShowShopDialog: (open: boolean) => void;
  showRegistrationFlowCompletedDialog: boolean;
  showShopDialog: boolean;
}

export const DEFAULT_DASHBOARD_CONTEXT = {} as DashboardContextValue;

export const DEFAULT_FAVORITE_DEALER_ID = 'BR066492';

export enum AccountStatuses {
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  INVITED = 'INVITED',
  PENDING = 'PENDING',
}

export const accountStatus = (statuses?: UserStatus) => {
  if (!statuses) return AccountStatuses.DECLINED;

  const { AccountVerfified, EmailVerified } = statuses;
  // If account is verified then it's obvious, that it's active;
  if (AccountVerfified) return AccountStatuses.ACTIVE;
  // If account is NOT verified but Email is verified, then it's pending;
  if (EmailVerified) return AccountStatuses.PENDING;
  // Rest of the cases go to one bucket: declined.
  return AccountStatuses.DECLINED;
};
