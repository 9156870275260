import { Box, generateUtilityClasses, Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const classes = generateUtilityClasses('ProgressBar', [
  'progressLineWrapper',
  'vehiclePictureTransformed',
  'vehicleWrapper',
  'progressBarLine',
]);

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.progressLineWrapper}`]: {
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
    },
  },

  [`& .${classes.vehiclePictureTransformed}`]: {
    position: 'relative',
    top: theme.spacing(0.5),
    transition: '0.6s ease-in-out',
    width: '160px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '120px',
    },
  },

  [`& .${classes.vehicleWrapper}`]: {
    marginTop: theme.spacing(2.125),
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginTop: theme.spacing(0),
      width: '100%',
    },
  },

  [`& .${classes.progressBarLine}`]: {
    '& > div': {
      borderRadius: '10px',
      transition: '0.6s ease-in-out',
    },
    background: '#B4B4B4',
    borderRadius: '10px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginRight: theme.spacing(2),
    },
  },
}));

export interface ProgressBarProps {
  truthyStatusCount: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { truthyStatusCount } = props;

  const matchMobileDevices = useMediaQuery('(max-width:960px)');
  const { t } = useTranslation();
  const [progressValue, setProgress] = useState<number>(0);
  const progressLineMultiplier = 33; // there are three variables that can be shown here (0, 1, 2)
  // that's why on the progress bar percentages can only be 0, 33% or 66%
  // (so we divide 100 / 3 and have got our multiplier).
  // If there is 99%/100% entire component is hidden.
  const vehiclePositionModifier = matchMobileDevices ? 120 : 160; // vehicle should be moved backwards by this size in
  // pixels to be positioned properly.
  const linearProgressWidth = progressValue * progressLineMultiplier;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(truthyStatusCount);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [truthyStatusCount]);

  return (
    <StyledGrid
      container
      item
      justifyContent="flex-start"
      data-testid="progress-bar-component"
    >
      <Grid className={classes.vehicleWrapper}>
        <img
          src="/assets/img/icon_vehicle.svg"
          alt={t('myGRIMME_core_maschine', 'Maschine')}
          className={classes.vehiclePictureTransformed}
          style={{
            left: `calc(${linearProgressWidth}% - ${
              linearProgressWidth ? vehiclePositionModifier : 0
            }px)`,
          }}
          data-testid="progress-bar-vehicle"
        />
      </Grid>
      <Grid className={classes.progressLineWrapper}>
        <Box display="flex" alignItems="center">
          <Box width="100%">
            <LinearProgress
              variant="determinate"
              value={linearProgressWidth}
              data-testid="progress-bar-line"
              className={classes.progressBarLine}
            />
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  );
};
