import { useSession } from '@grimme/next-grimme-auth';
import { AxiosRequestConfig, RawAxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import useSWR, { Key, SWRConfiguration } from 'swr';
import useSWRMutation, { type SWRMutationConfiguration } from 'swr/mutation';
import { SWRResult } from './types';

/**
 * Wrapper around useSWR to fetch data from an authenticated API endpoint.
 *
 * The Axios config can be passed in as a parameter. But the Authorization header
 * will be set automatically with the access token from the session. Setting a
 * header config will override the Authorization header.
 *
 * @param url - The URL to fetch
 * @param config - The Axios config to use
 * @param swrConfig - An optional SWR config to use
 * @returns {SWRResult} The SWR result
 */
// TODO: We should deprecated this function and use useSWRAuthenticatedForServices instead
export function useDeprecatedSWRAuthenticated<T>(
  {
    config,
    url,
  }: {
    config?: AxiosRequestConfig;
    url: string | undefined | null;
  },
  swrConfig?: SWRConfiguration,
): SWRResult<T> {
  // Get the session data and status
  const { data: sessionData, status } = useSession();
  // Use SWR to fetch the data
  const { data, error, isLoading, isValidating, mutate } = useSWR<T>(() => {
    // If the user is not authenticated, return null to not fetch anything
    if (status !== 'authenticated') return null;

    // Return the url and config for the SWR fetcher and
    // set the Authorization header with the access token
    return {
      config: {
        headers: {
          Authorization: `Bearer ${sessionData?.accessToken}`,
        },
        ...config,
      },
      url,
    };
  }, swrConfig);

  // Return the SWR result with the data, error and loading status
  return {
    data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export const useAuthSWR = <TResponseData = unknown>(
  key: Key,
  apiCall: (
    config: RawAxiosRequestConfig | undefined,
  ) => Promise<TResponseData>,
  options?: SWRConfiguration<TResponseData>,
) => {
  // NOTE: Get the session data and status
  const { data: sessionData } = useSession();
  const accessToken = sessionData?.accessToken;

  const fetcher = useCallback(
    ([_, accessToken]: [Key, string]) => {
      // NOTE: Include the token in the headers for the API call
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken || ''}`,
        },
      };

      return apiCall(config);
    },
    [apiCall],
  );

  // NOTE: Return useSWR as it is so that the caller can use it as if it was a default useSWR without losing the type
  return useSWR(accessToken ? [key, accessToken] : null, fetcher, {
    errorRetryCount: 5,
    errorRetryInterval: 100,
    shouldRetryOnError: true,
    ...options,
  });
};

export type AuthSWRMutationArgs<TArgs> = {
  args: TArgs;
  config: AxiosRequestConfig;
};

export const useAuthSWRMutation = <TArgs, TResponseData = unknown>(
  key: Key,
  apiCall: (
    config: RawAxiosRequestConfig | undefined,
    args: TArgs,
  ) => Promise<TResponseData>,
  // NOTE: Maybe there is a better way to type the unknowns here
  options?: SWRMutationConfiguration<
    TResponseData,
    unknown,
    Key,
    AuthSWRMutationArgs<TArgs>
  >,
) => {
  // NOTE: Get the session data and status
  const { data: sessionData } = useSession();
  const accessToken = sessionData?.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken || ''}`,
      ContentType: 'application/json',
    },
  };

  const fetcher = async (
    _: Key,
    { arg: args }: { arg: AuthSWRMutationArgs<TArgs> },
  ) => {
    // NOTE: Include the token in the headers for the API call

    return apiCall(args.config, args.args);
  };
  // NOTE: Return useSWRMutation as it is so that the caller can use it as if it was a default useSWR without losing the type
  const { trigger, ...rest } = useSWRMutation(key, fetcher, options);

  return {
    ...rest,
    trigger: (args: TArgs) => trigger({ args, config }),
  };
};
