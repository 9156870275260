import { TFunction } from 'i18next';
import { Machine } from '~/redux/utils/utils';
import {
  TelemetryData,
  TelemetryDataBySerial,
  TelemetryOnlineStatus,
} from './ApiQueryTypes';

export const getSortByTelemetryComparator =
  (telemetryData?: TelemetryDataBySerial) =>
  (machine1: Machine, machine2: Machine) => {
    const LAST = 100;
    const AFTER_ALL_STATUSES =
      Math.max(
        ...(Object.values(TelemetryOnlineStatus) as any[]).filter(
          (n) => !isNaN(n),
        ),
      ) + 1;

    const findOrder = (data?: TelemetryData) => {
      if (!data || Object.keys(data).length === 0) return LAST;
      if (data.status) return data.status;
      if (data.lastOnlineAt) return AFTER_ALL_STATUSES;
      return LAST;
    };

    return (
      findOrder(telemetryData?.[machine1.serial]) -
      findOrder(telemetryData?.[machine2.serial])
    );
  };

export const getTelemetryStatusTranslation = (
  status: TelemetryOnlineStatus | undefined,
  lastOnlineAt: string | undefined,
  t: TFunction,
) => {
  const isMachineError = status === TelemetryOnlineStatus.ERROR;
  const isMachineOnline = status === TelemetryOnlineStatus.ONLINE;
  const isMachineOffline = status === TelemetryOnlineStatus.OFFLINE;
  const isMachineUndefined = status === undefined;
  const interpolatedDate = { date: lastOnlineAt }; //for offlineSinceText {{date}}

  const errorText = t('myGRIMME_core_telemetry_status_unknown', 'Error');
  const offlineSinceText = t(
    'myGRIMME_core_telemetry_status_offline_text',
    'Offline since {{date}}',
    interpolatedDate,
  );
  const offlineSinceFallback = t(
    'myGRIMME_core_telemetry_status_offline_text_fallback',
    'Offline',
  );
  const offlineText = lastOnlineAt ? offlineSinceText : offlineSinceFallback;
  const onlineText = t('myGRIMME_core_telemetry_status_online', 'Online');
  const unknownText = t('myGRIMME_core_telemetry_status_other', 'Unknown');

  if (isMachineUndefined) {
    return unknownText;
  }

  if (isMachineError) {
    return errorText;
  }

  if (isMachineOffline) {
    return offlineText;
  }
  if (isMachineOnline) {
    return onlineText;
  }
};
