import { faShareNodes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTelemetryLoadingState } from '~/redux/telemetry.slice';
import { Machine, loadingStatus } from '~/redux/utils/utils';
import { TelemetryOnlineStatus } from '~/utils/ApiQueryTypes';
import { DEFAULT_MACHINE_IMAGE } from '~/utils/consts';
import { getTelemetryStatusTranslation } from '~/utils/machines';
import { reformatDate } from '~/utils/utils';
import { ImageWithFallback } from '../image-with-fallback';
import { MachineStatus, TelemetryStatusIcon } from '../status';
import { Status } from '../status/status/status';
import { StyledGrid, classes } from './MachineCard.styles';

// TODO: The mandatory type here should be a global Machine type
interface MachineCardProps {
  image: string;
  isTelemetryActive?: boolean;
  lastOnlineAt?: string;
  loading?: boolean;
  machineSharingContract?: {
    ownerCompany?: {
      name: string;
    };
  };
  machineStatus?: Machine['machineStatus'];
  name?: string;
  status?: TelemetryOnlineStatus;
  timestamp?: string;
}

interface TelemetryProps {
  lastOnlineAt?: string;
  status?: TelemetryOnlineStatus;
}

export const Telemetry = (props: TelemetryProps) => {
  const { lastOnlineAt, status } = props;
  const { t } = useTranslation();

  return (
    <>
      <TelemetryStatusIcon status={status} />
      <Typography variant="body2" className={classes.telemetryStatus}>
        {getTelemetryStatusTranslation(status, lastOnlineAt, t)}
      </Typography>
    </>
  );
};

export const MachineCard = ({
  image,
  isTelemetryActive,
  lastOnlineAt,
  machineSharingContract,
  machineStatus,
  name = '',
  status,
}: MachineCardProps) => {
  const { t } = useTranslation();
  const reformatedLastOnlineAt = lastOnlineAt && reformatDate(lastOnlineAt);
  const isMachineDeclined = machineStatus === 'declined';
  const isMachineInactive = machineStatus === 'inactive';
  const telemetryStatus = useSelector(selectTelemetryLoadingState);
  const isTelemetryNotLoaded = telemetryStatus === loadingStatus.NOT_LOADED;
  const isTelemetryLoaded = telemetryStatus === loadingStatus.LOADED;
  const isTelemetryLoading = telemetryStatus === loadingStatus.LOADING;
  const shouldShowLoader =
    isTelemetryActive && (isTelemetryLoading || isTelemetryNotLoaded);
  const shouldShowTelemetry = isTelemetryActive && isTelemetryLoaded;
  //TODO: Add translation key for "machine shared" by below;

  return (
    <StyledGrid className={classes.grid}>
      <div className="flex gap-2 justify-end">
        {Boolean(machineSharingContract) && (
          <Status
            avatar={<FontAwesomeIcon icon={faShareNodes} />}
            tooltip={t(
              'myGRIMME_core_sharing_machine_shared_tooltip',
              'This is a shared machine.',
            )}
            type="info"
          />
        )}
        {/* NOTE: Looks like we are on purpose filter the active state */}
        {machineStatus && machineStatus !== 'active' && (
          <Box className={`${classes.machineStatusContainer} ${machineStatus}`}>
            <MachineStatus status={machineStatus} hasText={false} />
          </Box>
        )}
      </div>
      <div className={classes.cardContentWrapper}>
        <div className={`${classes.cardContent} ${machineStatus}`}>
          <ImageWithFallback
            alt={name}
            declined={isMachineDeclined}
            fallback={DEFAULT_MACHINE_IMAGE}
            fill={true}
            imgUrl={image}
            inactive={isMachineInactive}
          />
        </div>
      </div>
      <Typography variant="body2" className={classes.machineName}>
        {name}
      </Typography>
      <Box className={classes.box}>
        {shouldShowLoader && (
          <CircularProgress className={classes.progress} size="small" />
        )}
        {shouldShowTelemetry && (
          <div className={classes.telemetryContainer}>
            <Telemetry lastOnlineAt={reformatedLastOnlineAt} status={status} />
          </div>
        )}
      </Box>
    </StyledGrid>
  );
};
