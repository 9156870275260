import Image, { ImageProps } from 'next/image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

export const classes = generateUtilityClasses('Image-With-Fallback', [
  'declined',
  'image',
  'inactive',
]);

export const StyledImage = styled(Image)(() => ({
  [`&.${classes.image}`]: {
    objectFit: 'contain',
  },
  [`&.${classes.declined}`]: {
    opacity: 0.6,
  },
  [`&.${classes.inactive}`]: {
    filter: 'grayscale(1)',
    opacity: 0.6,
  },
}));

interface ImageWithFallbackProps
  extends Omit<
    ImageProps,
    'blurDataURL' | 'onError' | 'placeholder' | 'src' | 'unoptimized'
  > {
  declined?: boolean;
  fallback: string;
  imgUrl: string;
  inactive?: boolean;
}

export const ImageWithFallback = ({
  alt,
  declined,
  fallback,
  imgUrl,
  inactive,
  quality,
  ...props
}: ImageWithFallbackProps) => {
  const [imgError, setImgError] = useState<boolean>(false);
  const { t } = useTranslation();

  const unknownAlt = t('myGRIMME_core_unknown', 'Unknown');

  const handleError = () => {
    setImgError(true);
  };

  const hasImgUrl = imgUrl !== '' && !imgError;
  const imgSrc = hasImgUrl ? imgUrl : fallback;
  const nextImgPlaceholder = hasImgUrl ? 'blur' : 'empty';
  const imageClasses = classNames(
    { [classes.declined]: declined },
    { [classes.inactive]: inactive },
    classes.image,
  );

  return (
    <StyledImage
      className={imageClasses}
      alt={alt || unknownAlt}
      blurDataURL={imgUrl}
      onError={handleError}
      placeholder={nextImgPlaceholder}
      quality={quality || 100}
      src={imgSrc}
      unoptimized
      {...props}
    />
  );
};
