import { OwnerListParameters } from '@/app/[locale]/(authenticated)/machine/[id]/_sections/_utils/utils';
import { type AxiosResponse } from 'axios';
import { SWRConfiguration, type Key } from 'swr';
import { type SWRMutationConfiguration } from 'swr/mutation';
import {
  BulkRemovalMachineSharingContractsRequestDto,
  ListOwnerMachineSharingContractsResponseDto,
  ListPendingApprovalMachineSharingContractsResponseDto,
  OfferMachineSharingContractRequestDto,
  OfferMachineSharingContractResponseDto,
  ProbeOfferMachineSharingContractResponseDto,
  RequestMachineSharingContractRequestDto,
  RequestMachineSharingContractResponseDto,
  SharedMachineDto,
  type ApproveMachineSharingContractResponseDto,
} from '~/gen/grid-user';
import { userGridApi } from '~/utils/rest-apis/grid/api';
import { ProbeOfferData } from './SWR/types';
import {
  AuthSWRMutationArgs,
  useAuthSWR,
  useAuthSWRMutation,
} from './SWR/useSWRAuthenticated';

//Note: naming convention pattern for base: [machine-sharing-[...]]-[your-key];
export const swrQueryKeys = {
  machineSharingAddSharing: 'machine-sharing-contracts-contracts-add-sharing',
  machineSharingContractsApprove: 'machine-sharing-contracts-approve',
  machineSharingContractsDecline: 'machine-sharing-contracts-decline',
  machineSharingContractsPendingApproval:
    'machine-sharing-contracts-pending-approval',
  machineSharingContractsSharedWithMe:
    'machine-sharing-contracts-shared-with-me',
  machineSharingDeleteMultipleSharings:
    'machine-sharing-contracts-delete-multiple-sharings',
  machineSharingDeleteSharing: 'machine-sharing-contracts-delete-sharing',
  machineSharingFetchOwnerSharings: 'machine-sharing-contracts-fetch-sharing',
  machineSharingProbeOffer: 'machine-sharing-contracts-probe-offer',
  machineSharingRequestSharing: 'machine-sharing-contracts-request-sharing',
};

export function useSWRMachinesSharingContractsPendingApproval(
  options?: SWRConfiguration,
) {
  return useAuthSWR<
    AxiosResponse<ListPendingApprovalMachineSharingContractsResponseDto>
  >(
    swrQueryKeys.machineSharingContractsPendingApproval,
    (config) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerGetPendingMachineShares(
        config,
      ),
    options,
  );
}

// NOTE: IDK if maybe we can research more on do these types less verbose
export function useSWRMachinesSharingContractsApproveMutation(
  options?: SWRMutationConfiguration<
    AxiosResponse<ApproveMachineSharingContractResponseDto>,
    unknown,
    Key,
    AuthSWRMutationArgs<string>
  >,
) {
  return useAuthSWRMutation<
    string,
    AxiosResponse<ApproveMachineSharingContractResponseDto>
  >(
    swrQueryKeys.machineSharingContractsApprove,
    (config, id) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerApproveMachineSharingContract(
        id,
        config,
      ),
    options,
  );
}

export function useSWRMachinesSharingContractsDeclineMutation(
  options?: SWRMutationConfiguration<
    AxiosResponse<void>,
    unknown,
    Key,
    AuthSWRMutationArgs<string>
  >,
) {
  return useAuthSWRMutation<string, AxiosResponse<void>>(
    swrQueryKeys.machineSharingContractsDecline,
    (config, id) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerDeclineMachineSharingContract(
        id,
        config,
      ),
    options,
  );
}

export function useSWRMachinesSharingContractsRemoveContract(
  options?: SWRMutationConfiguration<
    AxiosResponse<void>,
    unknown,
    Key,
    AuthSWRMutationArgs<string>
  >,
) {
  return useAuthSWRMutation<string, AxiosResponse<void>>(
    swrQueryKeys.machineSharingDeleteSharing,
    (config, id) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerRemoveMachineSharingContract(
        id,
        config,
      ),
    options,
  );
}

export function useSWRMachinesSharingContractsRemoveMultipleContracts(
  options?: SWRMutationConfiguration<
    AxiosResponse<void>,
    unknown,
    Key,
    AuthSWRMutationArgs<BulkRemovalMachineSharingContractsRequestDto>
  >,
) {
  return useAuthSWRMutation<
    BulkRemovalMachineSharingContractsRequestDto,
    AxiosResponse<void>
  >(
    swrQueryKeys.machineSharingDeleteMultipleSharings,
    (config, data) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerRemoveMachineSharingContractBulk(
        data,
        config,
      ),
    options,
  );
}

export function useSWRMachinesSharingContractsAddContract(
  options?: SWRMutationConfiguration<
    AxiosResponse<OfferMachineSharingContractResponseDto>,
    unknown,
    Key,
    AuthSWRMutationArgs<OfferMachineSharingContractRequestDto>
  >,
) {
  return useAuthSWRMutation<
    OfferMachineSharingContractRequestDto,
    AxiosResponse<OfferMachineSharingContractResponseDto>
  >(
    swrQueryKeys.machineSharingAddSharing,
    (config, data) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerOfferMachineSharingContracts(
        data,
        config,
      ),
    options,
  );
}

export function useSWRMachineSharingContracts(
  data: OwnerListParameters,
  key?: string,
  options?: SWRConfiguration,
) {
  return useAuthSWR<AxiosResponse<ListOwnerMachineSharingContractsResponseDto>>(
    key ?? swrQueryKeys.machineSharingFetchOwnerSharings,
    (config) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerListOwnerMachineSharingContracts(
        data?.recipientCompanyBR,
        data.machineSerialNumber,
        data?.isOwnerConsentGive, //it should be 'given'
        data?.isRecipientConsentGiven,
        config,
      ),
    options,
  );
}

export function useSWRMachinesSharingContractsRequestContract(
  options?: SWRMutationConfiguration<
    AxiosResponse<RequestMachineSharingContractResponseDto>,
    unknown,
    Key,
    AuthSWRMutationArgs<RequestMachineSharingContractRequestDto>
  >,
) {
  return useAuthSWRMutation<
    RequestMachineSharingContractRequestDto,
    AxiosResponse<RequestMachineSharingContractResponseDto>
  >(
    swrQueryKeys.machineSharingRequestSharing,
    (config, data) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerRequestMachineSharingContracts(
        data,
        config,
      ),
    options,
  );
}

export function useSWRMachinesSharingContractsSharedWithMe(
  options?: SWRConfiguration,
) {
  return useAuthSWR<AxiosResponse<SharedMachineDto[]>>(
    swrQueryKeys.machineSharingContractsSharedWithMe,
    (config) =>
      userGridApi.machinesApi.currentUserMachinesControllerGetMachinesSharedWithMe(
        config,
      ),
    options,
  );
}

export function useSWRMachineSharingContractsProbeOffer(
  data: ProbeOfferData,
  key?: string,
  options?: SWRConfiguration,
) {
  return useAuthSWR<AxiosResponse<ProbeOfferMachineSharingContractResponseDto>>(
    key ?? swrQueryKeys.machineSharingProbeOffer,
    (config) =>
      userGridApi.machineSharingContracts.currentUserMachineSharingContractsControllerProbeMachineSharingContractOffer(
        data.br,
        data.email,
        data.serials,
        config,
      ),
    options,
  );
}
