import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledIconButton, classes } from './styles';

interface IAddButton {
  autoMargin?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const AddButton = (props: IAddButton) => {
  const { autoMargin, disabled, onClick } = props;
  return (
    <StyledIconButton
      disabled={disabled}
      data-testid="iconButton"
      size="large"
      onClick={onClick}
      className={autoMargin ? classes.machinesListAddIcon : undefined}
    >
      <FontAwesomeIcon
        icon={faPlus}
        data-testid="icon"
        className={classes.addIcon}
      />
    </StyledIconButton>
  );
};
