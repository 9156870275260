import { createContext, useContext } from 'react';
import { environment } from '../environments/environment';

export const environmentConstants = {
  ...environment,
  contactEmail: 'mygrimme@grimme.de',
  defaultLanguage: 'de',
};

const ConfigurationContext = createContext(environmentConstants);

export const useConfiguration = () => useContext(ConfigurationContext);
