import { Footer as GrimmeFooter } from '@grimme/components';
import { GlobalSettings } from '../../utils';
import {
  getFooterData,
  getNavigationLinks,
  getSitemap,
  getSocialLinks,
} from './utils';

export type FooterProps = {
  footerData: GlobalSettings['footer'];
  navigationLinks: GlobalSettings['navigation_menu'];
  socialLinks: GlobalSettings['social_links'];
  topLinks: GlobalSettings['top_links'];
};

export const Footer = (props: FooterProps) => {
  const { footerData, navigationLinks, socialLinks, topLinks } = props;
  const navigation = getNavigationLinks(navigationLinks);

  return (
    <GrimmeFooter
      data={getFooterData(footerData)}
      socialLinks={getSocialLinks(socialLinks)}
      sitemap={getSitemap(footerData, navigation, topLinks)}
    />
  );
};
