import {
  createAsyncThunk,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { TelemetryData, TelemetryDataBySerial } from '~/utils/ApiQueryTypes';
import { getTelemetryData } from '../utils/ApiQuery';
import { loadingStatus } from './utils/utils';

export interface TelemetryState extends EntityState<TelemetryData, string> {
  error?: string;
  status: loadingStatus;
}

export const TELEMETRY_FEATURE_KEY = 'telemetry';

export const initialState = {
  entities: {},
  error: undefined,
  ids: [],
  status: loadingStatus.NOT_LOADED,
} satisfies TelemetryState;

export const fetchTelemetryData = createAsyncThunk(
  'telemetry/fetch',
  async (args: { accessToken: string; country?: string; serials: string[] }) =>
    getTelemetryData(args.serials, args.accessToken, args.country),
);

export const telemetrySlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchTelemetryData.pending, (state: TelemetryState) => {
        state.status = loadingStatus.LOADING;
      })
      .addCase(
        fetchTelemetryData.fulfilled,
        (
          state: TelemetryState,
          action: PayloadAction<TelemetryDataBySerial | undefined>,
        ) => {
          state.entities = { ...state.entities, ...action.payload };
          state.ids = Object.keys(state.entities);
          state.error = undefined;
          state.status = loadingStatus.LOADED;
        },
      )
      .addCase(fetchTelemetryData.rejected, (state: TelemetryState, action) => {
        state.error = action.error.message;
        state.status = loadingStatus.ERROR;
      });
  },
  initialState,
  name: TELEMETRY_FEATURE_KEY,
  reducers: {},
});

export const telemetryReducer = telemetrySlice.reducer;

export const telemeryActions = telemetrySlice.actions;

export const getTelemetryState = (rootState: {
  [TELEMETRY_FEATURE_KEY]: TelemetryState;
}): TelemetryState => rootState[TELEMETRY_FEATURE_KEY];

export const selectTelemetryEntities = createSelector(
  getTelemetryState,
  (state) => (state?.entities || {}) as TelemetryDataBySerial,
);

export const selectTelemeryEntityDataBySerial = (serial: string) =>
  createSelector(selectTelemetryEntities, (entity) => entity[serial]);

export const selectTelemetryLoadingState = createSelector(
  getTelemetryState,
  (state) => state.status,
);

export const telemetryStatus = createSelector(
  getTelemetryState,
  (state) => state.status,
);
