import { Button } from '@grimme/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from '~/utils/auth/signOut';

export const LogoutButton = () => {
  const insights = useAppInsightsContext();
  const { t } = useTranslation();

  const handleLogout = React.useCallback(() => {
    insights.trackEvent({ name: 'Logout' });
    signOut();
  }, [insights]);

  return (
    <Button onClick={handleLogout} variant="secondary">
      {t('myGRIMME_logout', 'Logout')}
    </Button>
  );
};
