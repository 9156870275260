import { Box, generateUtilityClasses, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * NOTE: The LoggedInMenu from GL can not be used for core,
 * since there we use a Link from nextJS with href prop for the links.
 * This is a temporary workaround for the issue. The change was not
 * implemented in the LoggedInMenu from GL, because we plan anyways to
 * rid of react router in this project and replace it with nextJS routing
 * in the future. Also, it was not done there to avoid any possible
 * issue in the other projects that use the LoggedInMenu from GL.
 */

export const classes = generateUtilityClasses(`LoggedInMenu-adaptedForCore`, [
  'root',
  'link',
  'linkContent',
]);

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    svg: {
      maxHeight: theme.spacing(2),
      maxWidth: theme.spacing(2),
      minHeight: theme.spacing(2),
      minWidth: theme.spacing(2),
    },
  },
  [`& .${classes.link}`]: {
    color: 'inherit',
    textDecoration: 'none',
  },

  [`& .${classes.linkContent}`]: {
    '&:hover': {
      [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        color: theme.palette.primary.main,
        svg: {
          color: theme.palette.primary.main,
        },
      },
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginTop: theme.spacing(2),
  },
}));

type LoggedInMenuProps = {
  company?: string;
  image?: ReactNode;
  menuItems?: {
    icon: JSX.Element;
    link?: string;
    text: string;
  }[];
  name: string;
};

const routesPath = {
  account: 'account',
  admin: 'admin',
  crops: 'crops',
  dashboard: 'home',
  machines: 'machines',
  remote: 'remote',
};

export const LoggedInMenu = (props: LoggedInMenuProps) => {
  const { company, image, menuItems, name } = props;
  const { i18n } = useTranslation();

  const updatedMenuLinks = menuItems?.map((item) => {
    const link = item.link;
    const matchedPath = Object.entries(routesPath).find(([_key, path]) =>
      link?.includes(path),
    );
    if (matchedPath) {
      const [_key, path] = matchedPath;
      item.link = `/${i18n.language}/${path}`;
    } else {
      item.link = undefined;
    }
    return item;
  });

  return (
    <StyledBox>
      <Box mt={3} display="flex">
        <>
          {image && image}
          <Box ml={2}>
            <Typography variant="h4">{name}</Typography>
            <Typography color="grey.400" variant="caption">
              {company}
            </Typography>
          </Box>
        </>
      </Box>

      <Box pt={1}>
        {updatedMenuLinks?.map(
          ({ icon, link, text }, index) =>
            link && (
              // TODO: in order to service both react router dom + app router using plain a tag. replace with next/link when migration is complete
              <a
                data-testid={`${text}-link`}
                className={classes.link}
                href={link}
                key={index}
              >
                <Box className={classes.linkContent}>
                  {icon}
                  <Typography ml={2} variant="body1">
                    {text}
                  </Typography>
                </Box>
              </a>
            ),
        )}
      </Box>
    </StyledBox>
  );
};
