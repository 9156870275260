import { TitleDivider } from '@grimme/components';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { prepareFullHDImage } from '../../utils/utils';
import { StyledGrid, classes } from './styles';

export interface IFiftyFiftyProps {
  children?: React.ReactNode;
  icon?: string;
  img?: string;
  inverted?: boolean;
  title?: string;
}

export const FiftyFifty = (props: PropsWithChildren<IFiftyFiftyProps>) => {
  const { children, icon, img, inverted, title } = props;
  const imageUrlStyle = `url('${prepareFullHDImage(
    img,
  )}'), url('/assets/img/overlay.png')`;

  return (
    <StyledGrid className={classes.root}>
      <div
        data-testid="fifty-fifty-grid"
        className={classNames(
          { [classes.inverse]: inverted },
          classes.container,
        )}
      >
        <div
          data-testid="left-column"
          className={inverted ? classes.orderInverted : classes.orderRegular}
        >
          <div className={classes.dataContainer}>
            <div
              className={
                inverted ? classes.textContainer : classes.textContainerInverted
              }
            >
              <div className={classes.iconContainer}>
                <img
                  src={icon}
                  alt={title}
                  title={title}
                  className={classes.iconRegular}
                />
              </div>
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
              <TitleDivider className={classes.divider} />
              <Typography variant="body1">{children}</Typography>
            </div>
          </div>
        </div>
        <div
          data-testid="right-column"
          className={`${classes.imageContainer} ${classes.overlay} 
          }`}
          style={{
            backgroundImage: imageUrlStyle,
          }}
        ></div>
      </div>
    </StyledGrid>
  );
};
