import { generateUtilityClasses, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('MachineCard', [
  'box',
  'cardContent',
  'cardContentWrapper',
  'grid',
  'idicator',
  'machineName',
  'progress',
  'root',
  'telemetryContainer',
  'telemetryStatus',
  'timestamp',
  'machineStatusContainer',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.box}`]: {
    height: theme.spacing(3),
  },
  [`&.${classes.grid}`]: {
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    textDecoration: 'none',
  },
  [`& .${classes.machineStatusContainer}`]: {
    '&.active': {
      opacity: 0,
    },
    alignSelf: 'end',
  },
  [`& .${classes.cardContent}`]: {
    '&.declined': {
      opacity: 0.5,
    },
    '&.inactive': {
      filter: 'grayscale(100%)',
    },
    display: 'flex',
    height: 90,
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 68,
    },
    position: 'relative',
    width: '100%',
  },
  [`& .${classes.cardContentWrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.idicator}`]: {
    height: 90,
    width: 90,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 66,
      width: 66,
    },
  },
  [`& .${classes.machineName}`]: {
    marginBottom: theme.spacing(0.5),
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(0.5),
      paddingTop: '0px',
    },
  },
  [`& .${classes.progress}`]: {
    height: theme.spacing(2),
    margin: theme.spacing(0.5, 1, 0.5, 1),
    width: theme.spacing(2),
  },
  [`& .${classes.root}`]: {
    boxShadow: 'none',
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      '&:hover': {
        transform: 'translate(0, -5px)',
      },
    },
    width: '100%',
  },
  [`& .${classes.telemetryContainer}`]: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  [`& .${classes.telemetryStatus}`]: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginLeft: theme.spacing(0.25),
    },
  },
  [`& .${classes.timestamp}`]: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(1),
  },
}));
