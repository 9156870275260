import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button, Slide, Slider } from '@grimme/components';
import { useSession } from '@grimme/next-grimme-auth';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRMachinesSharingContractsSharedWithMe } from '@/lib/machine-sharing';
import { PermissionGroupPresets, useHasPermission } from '~/hooks';
import { useMachinesList } from '~/hooks/machines';
import { useTelemetry } from '~/hooks/machines/use-machines-list/use-telemetry';
import { MachineMapper } from '~/redux/utils/mappers/machine.mapper';
import { ConnectivityApiService } from '@/lib/apis';
import { AddButton } from '../add-button';
import { LinkCardAppRouter } from '../link-card/link-card-app-router';
import { getSortByTelemetryComparator } from '../../utils/machines';
import { MachineCard } from './MachineCard';
import {
  StyledAddMachineButton,
  StyledGrid,
  classes,
} from './MachineCarousel.styles';

//TODO: maybe different name? and maybe it should be moved to some other place?
enum machineRedirections {
  MACHINE = 'machine',
  SHARED_MACHINE = 'shared-machine',
}

const AddNewMachine = () => {
  const { i18n, t } = useTranslation();
  return (
    <StyledAddMachineButton data-testid="my-machines">
      <LinkCardAppRouter
        icon={faPlus}
        title={t('myGRIMME_core_my_machines', 'Meine Maschinen')}
        to={`/${i18n.language}/machines`}
      />
    </StyledAddMachineButton>
  );
};

export const MachineCarousel = () => {
  const { data: sessionData } = useSession();
  const { fetchTelemetry, telemetry: telemetryData } = useTelemetry();
  const { machines: userMachinesV2 } = useMachinesList();

  const { data: sharedWithMeMachinesRawData } =
    useSWRMachinesSharingContractsSharedWithMe();
  const insights = useAppInsightsContext();
  const hasTelemetryRights = useHasPermission(
    PermissionGroupPresets.HAS_TELEMETRY_ACCESS,
  );

  const { i18n, t } = useTranslation();

  const trackingEvent = (name: string, target: string) => {
    return insights.trackEvent({
      name: name,
      properties: {
        target: target,
      },
    });
  };

  const { machines, serials } = useMemo(() => {
    const machines = [
      ...userMachinesV2,
      ...(sharedWithMeMachinesRawData?.data ?? []),
    ].map(MachineMapper.MapGridToMachine);

    const serials = machines
      ?.filter((machine) => machine.isTelemetryActive)
      .map((machine) => machine.serial);

    return { machines, serials };
  }, [userMachinesV2, sharedWithMeMachinesRawData]);

  const sortedMachinesByTelemetry = useMemo(() => {
    if (!machines) return [];

    return machines?.sort(getSortByTelemetryComparator(telemetryData));
  }, [machines, telemetryData]);

  const hasMachines = machines && machines?.length > 0;

  React.useEffect(() => {
    if (hasTelemetryRights && sessionData?.accessToken && !!serials?.length) {
      fetchTelemetry(sessionData.accessToken, serials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sessionData?.accessToken,
    fetchTelemetry,
    hasTelemetryRights,
    serials?.length,
  ]);

  if (!hasMachines) return <AddNewMachine />;

  return (
    <>
      <Slider dots={false} modifier="dark">
        {sortedMachinesByTelemetry.map((machine, index) => {
          // TODO: We can just pass the values directly to the MachineCard not need to create consts I'd say
          const image = ConnectivityApiService.getMachineImageURLBySerial(
            machine.serial,
          );
          const subpageRedirection = machine.machineSharingContract
            ? machineRedirections.SHARED_MACHINE
            : machineRedirections.MACHINE;
          const linkHref = `/${subpageRedirection}/${machine?.serial?.toLowerCase()}`;
          const isTelemetryActive =
            hasTelemetryRights && machine?.isTelemetryActive;
          const lastOnlineAt = telemetryData?.[machine?.serial]?.lastOnlineAt;
          const status = telemetryData?.[machine?.serial]?.status;
          const timestamp = telemetryData?.[machine?.serial]?.timestamp;
          const machineStatus = machine.machineStatus;

          return (
            <Slide href={linkHref} key={index} type="custom">
              <MachineCard
                /*
                  TODO: Looks like this Card is specific for this Carousel context so we should pass
                  the machine object instead of the individual properties we will have the full
                  control if we have the right Machine type there
                */
                image={image}
                isTelemetryActive={isTelemetryActive}
                key={index}
                lastOnlineAt={lastOnlineAt}
                name={machine?.name}
                status={status}
                timestamp={timestamp}
                machineStatus={machineStatus}
                machineSharingContract={machine.machineSharingContract}
              />
            </Slide>
          );
        })}
      </Slider>
      <StyledGrid className={classes.root}>
        <Link className={classes.link} href={`/${i18n.language}/machines`}>
          <Button
            modifier="dark"
            onClick={() => trackingEvent('Opened Link', 'machines list')}
            variant="secondary"
          >
            {t('myGRIMME_core_show_all', 'Alle Anzeigen')}
          </Button>
        </Link>
        <Link href={`/${i18n.language}/home/new`} scroll={false}>
          <AddButton />
        </Link>
      </StyledGrid>
    </>
  );
};
