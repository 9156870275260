import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { PropsWithChildren } from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { StyledGrid, classes } from './styles';

export type LinkCardProps = {
  'data-testid'?: string;
  disabled?: boolean;
  icon: IconProp;
  image?: string;
  inactiveCardClick?: React.MouseEventHandler<HTMLDivElement>;
  locale?: string;
  onClick?: MouseEventHandler;
  target?: string;
  title: string;
  to: string | URL;
};

export const LinkCardAppRouter = (props: PropsWithChildren<LinkCardProps>) => {
  const {
    children,
    disabled,
    icon,
    image,
    inactiveCardClick,
    locale,
    onClick,
    target,
    title,
    to,
    ...rest
  } = props;
  const cardProps = disabled
    ? {
        onClick: inactiveCardClick,
      }
    : {
        onClick: () => void 0,
      };

  const {
    i18n: { language: currentLocale },
  } = useTranslation();

  const cardContent = (
    <Card className={classes.card} {...cardProps} {...rest}>
      <CardContent className={classes.cardContent}>
        <Grid className={classes.header}>
          {image ? (
            <img alt="" src={image} className={classes.icon} />
          ) : (
            <FontAwesomeIcon icon={icon} className={classes.icon} />
          )}
          <Typography className={classes.title} variant="body2" component="p">
            {title}
          </Typography>
        </Grid>
        {children && (
          <Typography
            className={classes.content}
            variant="body2"
            color="textSecondary"
            component="p"
            data-testid="linkcard-content"
          >
            {children}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
  return (
    <StyledGrid
      item
      className={classNames(classes.root, {
        [classes.disabled]: disabled,
      })}
    >
      {disabled || !to ? (
        cardContent
      ) : (
        <Link
          href={to}
          locale={locale || currentLocale}
          className={classes.link}
          target={target}
          onClick={onClick}
        >
          {cardContent}
        </Link>
      )}
    </StyledGrid>
  );
};
