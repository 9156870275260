import { Dialog } from '@grimme/components';
import { Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

export interface IAlertDialogLogicalProps {
  handleCancel: () => void;
  handleOk?: () => void;
  open: boolean;
}

interface IAlertDialogProps extends IAlertDialogLogicalProps {
  message: string;
  textCancel: string;
  textOk?: string;
  title?: string;
}

export const AlertDialog = (props: IAlertDialogProps) => {
  const { handleCancel, handleOk, open, textCancel, textOk, title } = props;
  const dialogTitle = title || '';

  return (
    <Dialog
      labelCancel={textCancel}
      labelConfirm={textOk}
      onCancel={handleCancel}
      onClose={handleCancel}
      onConfirm={handleOk}
      open={open}
      title={dialogTitle}
    >
      <DialogContent>
        <Typography variant="h5">{props.message}</Typography>
      </DialogContent>
    </Dialog>
  );
};
