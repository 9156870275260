import { SharedMachineNameDto } from '@/app/[locale]/(authenticated)/shared-machine/_sections/_utils/utils';
import { type AxiosResponse } from 'axios';
import { Key, SWRConfiguration } from 'swr';
import { SWRMutationConfiguration } from 'swr/dist/mutation';
import {
  MachineDto,
  MachineWithOwnershipsGetResponseDto,
  SharedMachineDto,
  UpdateMachineSharedResponseDto,
} from '~/gen/grid-user';
import { userGridApi } from '~/utils/rest-apis/grid/api';
import {
  AuthSWRMutationArgs,
  useAuthSWR,
  useAuthSWRMutation,
} from './SWR/useSWRAuthenticated';

//Note: naming convention pattern for base: [machine/s]-[your-key];
export const swrQueryKeys = {
  machineContractPatchedName: 'machine-contract-patched-name',
  machineContractSharedWithMe: 'machine-contract-shared-with-me',
  machineNewMachineAdded: 'machine-new-machine-added',
  machineWithOwnership: 'machine-with-ownership',
  machinesList: 'machines-list',
};

export function useSWRMachinesSharingContractSharedWithMe(
  serialNumber: string,
  options?: SWRConfiguration,
) {
  return useAuthSWR<AxiosResponse<SharedMachineDto>>(
    swrQueryKeys.machineContractSharedWithMe,
    (config) =>
      userGridApi.machinesApi.currentUserMachinesControllerGetMachineSharedWithMe(
        serialNumber,
        config,
      ),
    options,
  );
}

export function useSWRMachinesAddNewMachine(
  options?: SWRMutationConfiguration<
    AxiosResponse<MachineDto>,
    unknown,
    Key,
    AuthSWRMutationArgs<SharedMachineNameDto>
  >,
) {
  return useAuthSWRMutation<SharedMachineNameDto, AxiosResponse<MachineDto>>(
    swrQueryKeys.machineNewMachineAdded,
    (config, machine) =>
      userGridApi.machinesApi.currentUserMachinesControllerPostMachine(
        machine,
        config,
      ),
    options,
  );
}

export function useSWRMachinesPatchMachineName(
  options?: SWRMutationConfiguration<
    AxiosResponse<UpdateMachineSharedResponseDto>,
    unknown,
    Key,
    AuthSWRMutationArgs<SharedMachineNameDto>
  >,
) {
  return useAuthSWRMutation<
    SharedMachineNameDto,
    AxiosResponse<UpdateMachineSharedResponseDto>
  >(
    swrQueryKeys.machineContractPatchedName,
    (config, machine) =>
      userGridApi.machinesApi.currentUserMachinesControllerUpdateMachineSharedWithMe(
        machine.serialNumber,
        { customMachineName: machine.customMachineName },
        config,
      ),
    options,
  );
}

export function useSWRMachinesList(options?: SWRConfiguration<MachineDto[]>) {
  return useAuthSWR<MachineDto[]>(
    swrQueryKeys.machinesList,
    async (config) => {
      const response =
        await userGridApi.machinesApi.currentUserMachinesControllerGetAllMachines(
          config,
        );

      return response.data;
    },
    options,
  );
}

export function useSWRMachineWithOwnership(
  serial: string,
  options?: SWRConfiguration,
) {
  return useAuthSWR<AxiosResponse<MachineWithOwnershipsGetResponseDto>>(
    swrQueryKeys.machineWithOwnership + serial,
    (config) =>
      userGridApi.machines.machinesApiControllerGetMachineWithOwnerships(
        serial,
        config,
      ),
    options,
  );
}
