import { useDispatch } from 'react-redux';
import { fetchMachines, selectAllMachines } from '~/redux/machines-v2.slice';
import { useSWRMachinesList } from '@/lib/machines';
import { useAppSelector } from '~/redux';

export const useMachinesList = () => {
  const dispatch = useDispatch();

  const { error, isLoading, isValidating } = useSWRMachinesList({
    onError: (err) => {
      dispatch((fetchMachines.rejected as any)(err));
    },
    onSuccess: (data) => {
      dispatch((fetchMachines.fulfilled as any)(data));
    },
  });

  /**
   * Returning data from reducer because for now it's still stays a single source of truth on data
   * That is affected by other operations mutating it, such as: add/remove machine
   * TODO: remove selector and return data directly from SWR hook above, once cache mutation established
   */
  const machinesGrid = useAppSelector(selectAllMachines);

  return {
    isError: !!error,
    isLoaded: !isLoading,
    isLoading: isValidating,
    machines: machinesGrid || [],
  };
};
