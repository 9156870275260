import { useTranslation } from 'react-i18next';
import { MachineDto } from '~/gen/grid-user';
import { Status, StatusProps } from './status';
import {
  MachineDeclinedAvatar,
  MachineInactiveAvatar,
  MachinePendingAvatar,
  MachineVerifiedAvatar,
} from './styles';
export interface MachineStatusProps {
  hasText?: boolean;
  status: MachineDto['status'];
}

// TODO: Delete this bridge component
export const MachineStatus = (props: MachineStatusProps) => {
  const { hasText = true, status } = props;

  const { t } = useTranslation();

  const labelVerified = t('myGRIMME_core_machine_status_verified', 'Verified');
  const labelPending = t('myGRIMME_core_machine_status_pending', 'Pending');
  const labelInactive = t('myGRIMME_core_machine_status_inactive', 'Inactive');
  const labelDeclined = t('myGRIMME_core_machine_status_declined', 'Declined');

  const tooltipVerified = t(
    'myGRIMME_core_machine_status_verified_tooltip',
    'Machine has been verified.',
  );
  const tooltipPending = t(
    'myGRIMME_core_machine_status_pending_tooltip',
    'Machine verification is pending.',
  );
  const tooltipInactive = t(
    'myGRIMME_core_machine_status_inactive_tooltip',
    'Machine is inactive.',
  );
  const tooltipDeclined = t(
    'myGRIMME_core_machine_status_declined_tooltip',
    'Machine ownership request is declined.',
  );

  const statusConfig: Record<MachineDto['status'], StatusProps> = {
    active: {
      avatar: MachineVerifiedAvatar,
      text: labelVerified.toUpperCase(),
      tooltip: tooltipVerified,
      type: 'success',
    },
    declined: {
      avatar: MachineDeclinedAvatar,
      text: labelDeclined.toUpperCase(),
      tooltip: tooltipDeclined,
      type: 'error',
    },
    inactive: {
      avatar: MachineInactiveAvatar,
      text: labelInactive.toUpperCase(),
      tooltip: tooltipInactive,
      type: 'inactive',
    },
    pending: {
      avatar: MachinePendingAvatar,
      text: labelPending.toUpperCase(),
      tooltip: tooltipPending,
      type: 'warning',
    },
  };

  const config = statusConfig[status];
  if (!hasText) delete config.text;

  return <Status {...config} />;
};
