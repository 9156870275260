//TODO: Remove this file and propose simples solution;
import React from 'react';

export const WaitableLink = (props: {
  children: React.ReactNode;
  className?: string;
  onClick: React.MouseEventHandler;
  style?: React.CSSProperties;
  target?: string;
  to?: string;
  waitTime?: number;
}) => {
  const { children, onClick, style, target, to, waitTime, ...rest } = props;
  return (
    <a
      href={to}
      onClick={(event) => {
        event.preventDefault();
        onClick(event);
        /**
         * onClick might have track calls
         * which gets triggered quickly but might take time to get success
         */
        setTimeout(() => {
          if (to) {
            if (target === '_blank') {
              window.open(to, target);
            } else {
              window.location.assign(to);
            }
          }
        }, waitTime || 1000);
      }}
      style={{ ...style, cursor: 'pointer' }}
      {...rest}
    >
      {children}
    </a>
  );
};
