import theme from '@grimme/theme';
import { Chip, Tooltip, Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { StyledBox, classes } from './styles';

export interface StatusProps {
  avatar: JSX.Element;
  className?: string;
  label?: string;
  text?: string;
  tooltip: string;
  type:
    | 'error'
    | 'warning'
    | 'success'
    | 'inactive'
    | 'disabled'
    | 'pending'
    | 'info';
}

export const Status = (props: StatusProps) => {
  const { avatar, className, label, text, tooltip, type } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tooltipPlacement = isMobile ? 'top' : 'top-end';

  const config = {
    disabled: { className: classes.disabled },
    error: { className: classes.error },
    inactive: { className: classes.inactive },
    info: { className: classes.info },
    pending: { className: classes.pending },
    success: { className: classes.success },
    warning: { className: classes.warning },
  };

  const chipClasses = classNames(config[type].className, {
    [classes.labelless]: !text,
  });

  return (
    <StyledBox className={className}>
      {label && (
        <Typography variant="h6" className={classes.title}>
          {label}
        </Typography>
      )}
      <Tooltip arrow placement={tooltipPlacement} title={tooltip}>
        <Chip avatar={avatar} className={chipClasses} label={text} />
      </Tooltip>
    </StyledBox>
  );
};
