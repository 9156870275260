import {
  IconLookup,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar, Visibility } from '@grimme/components';
import theme from '@grimme/theme';
import { Typography } from '@mui/material';
import { GlobalSettings } from '../../utils';
import { classes, StyledImagePlaceholder } from './styles';
import { emailData, phoneData, TContactDetails, whatsappData } from './types';

export const checkAssistantData = (
  error: unknown,
  data: TContactDetails | null,
) => {
  if (error) return false; //if error is returned from api, we do not show contact assistant;
  if (!data) return false; //if data is undefined or null, we do not show contact assistant;
  return true;
};

export const mapAssistantLinks = (
  assistantDetails: ReturnType<typeof reformatAssistantData>,
) => {
  const assistantLinks = assistantDetails?.map((link) => ({
    description: link.description,
    onClick: link.onClick,
    trackingData: link.trackingData,
  }));
  return assistantLinks;
};

//TODO: Refactor these functions, they are missing types, some code is not very readable
export const mapContactLinks = (
  contacts: ReturnType<typeof reformatAssistantData>,
) => {
  const icons = [
    <FontAwesomeIcon key={0} icon={faPhone} />,
    <FontAwesomeIcon key={1} icon={faEnvelope} />,
    <FontAwesomeIcon key={2} icon={faWhatsapp} />,
  ];
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {contacts?.map((detail, index) => (
        <div key={index}>
          {detail && (
            <Navbar.ContactLink
              icon={icons[index]}
              onClick={detail.onClick}
              title={detail.title}
            />
          )}
        </div>
      ))}
    </>
  );
};

export const mapSocialMediaLinks = (
  socialLinks: GlobalSettings['social_links'],
) => {
  return socialLinks?.links?.map((socialLink) => ({
    icon: (
      <FontAwesomeIcon
        icon={
          {
            iconName: socialLink.icon,
            prefix: socialLink.icon_type,
          } as IconLookup
        }
      />
    ),
    url: socialLink.url,
  }));
};

export const mapNavigationLinks = (
  navigationLinks: GlobalSettings['navigation_menu'],
) => {
  return navigationLinks
    ?.map((l) => l.fields)
    ?.map((link) => ({
      icon: (
        <FontAwesomeIcon
          icon={
            {
              iconName: link.icon,
              prefix: link.icon_type,
            } as IconLookup
          }
        />
      ),
      label: link.label,
      subitems:
        link.sub_items.length === 0
          ? undefined
          : link.sub_items.map((subitem) => ({
              label: subitem.label,
              url: subitem.url,
              visibility: subitem.visibility as Visibility,
            })),
      url: link.url,
      visibility: 'both' as Visibility,
    }));
};

//TODO: it was moved from navbar, has to be refactored, check with Verena if it is possible to add any fallback icon;
export const reformatAssistantData = (
  phone: phoneData,
  email: emailData,
  whatsapp: whatsappData,
) => {
  const { contactNumber, onPhoneClick } = phone;
  const { contactAssistantEmail, onEmailClick } = email;
  const { onWhatsAppClick, whatsappNumber, whatsappText } = whatsapp;

  const assistantDetails = [
    ...(contactNumber
      ? [
          {
            description: contactNumber,
            onClick: onPhoneClick,
            title: contactNumber,
            trackingData: { method: 'phone', rep: `${contactNumber}` },
          },
        ]
      : []),
    ...(contactAssistantEmail
      ? [
          {
            description: contactAssistantEmail,
            onClick: onEmailClick,
            title: contactAssistantEmail,
            trackingData: {
              method: 'email',
              rep: `${contactAssistantEmail}`,
            },
          },
        ]
      : []),
    ...(whatsappNumber
      ? [
          {
            description: whatsappText,
            onClick: onWhatsAppClick,
            title: whatsappText,
            trackingData: { method: 'whatsapp', rep: whatsappNumber },
          },
        ]
      : []),
  ];
  return assistantDetails;
};

export const reformatWhatsappNumber = (number: string) => {
  return number?.replace(/\s/g, '');
};

export const createImagePlaceHolder = (shortName: string) => {
  return (
    <StyledImagePlaceholder>
      <Typography className={classes.imageName} variant="h6">
        {shortName}
      </Typography>
    </StyledImagePlaceholder>
  );
};

export const mapLoggedOutMenuItems = (
  menu: GlobalSettings['logged_out_menu'],
) => {
  return menu.menu_items.map((item) => {
    return {
      icon: (
        <FontAwesomeIcon
          icon={[item.icon_type as IconPrefix, item.icon as IconName]}
          color={theme.palette.primary.main}
        />
      ),
      text: item.text,
    };
  });
};

export const mapLoggedInMenuItems = (
  menu: GlobalSettings['logged_in_menu'],
) => {
  return menu.menu_items.map((item) => {
    return {
      icon: (
        <FontAwesomeIcon
          icon={[item.icon_type as IconPrefix, item.icon as IconName]}
        />
      ),
      link: item.link,
      text: item.text,
    };
  });
};
