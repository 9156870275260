import { useTranslation } from 'react-i18next';
import { AccountStatuses } from '~/pages-components/dashboard/utils/utils';
import { Status, StatusProps } from './status';
import {
  AccountErrorAvatar,
  AccountPendingAvatar,
  AccountVerifiedAvatar,
} from './styles';

export interface AccountStatusProps {
  status: AccountStatuses;
  statusProps?: {
    className: string;
    label?: string;
  };
}

export const AccountStatus = (props: AccountStatusProps) => {
  const { status, statusProps } = props;

  const { t } = useTranslation();
  const label = t('myGRIMME_core_status_title', 'Account Status');
  const verifiedText = t('myGRIMME_core_status_verified', 'Verified');
  const errorText = t('myGRIMME_core_status_not_error', 'Not Verified');
  const pendingText = t('myGRIMME_core_status_pending', 'Pending');
  const errorTooltip = t(
    'myGRIMME_core_status_tooltip_not_verified',
    'Your account could not be verified. This means, it could not be connected to an existing customer account from our database and you have limited access to the functionalities of myGRIMME. If you have any questions please contact mygrimme@grimme.de.',
  );
  const pendingTooltip = t(
    'myGRIMME_core_status_tooltip_pending',
    'Your account has not been verified, yet. We are checking your account, soon. In the meantime you have limited access to the functionalities of myGRIMME.',
  );
  const verifiedTooltip = t(
    'myGRIMME_core_status_tooltip_verified',
    'Your account is verified. Thus, you have full access to all functionalities of myGRIMME.',
  );

  const statusConfig: Record<AccountStatuses, StatusProps> = {
    ACTIVE: {
      avatar: AccountVerifiedAvatar,
      text: verifiedText.toUpperCase(),
      tooltip: verifiedTooltip,
      type: 'success',
    },
    DECLINED: {
      avatar: AccountErrorAvatar,
      text: errorText.toUpperCase(),
      tooltip: errorTooltip,
      type: 'error',
    },
    INVITED: {
      avatar: AccountErrorAvatar,
      text: errorText.toUpperCase(),
      tooltip: errorTooltip,
      type: 'error',
    },
    PENDING: {
      avatar: AccountPendingAvatar,
      text: pendingText.toUpperCase(),
      tooltip: pendingTooltip,
      type: 'pending',
    },
  };

  const config = statusConfig[status] ?? statusConfig[AccountStatuses.DECLINED];

  return <Status label={label} {...config} {...statusProps} />;
};
