import { useUserCentricsConsent } from '@grimme/gdap';
import Script from 'next/script';
import { environment } from '~/environments';

export const UserCentrics = () => {
  //TODO: This is a temporary solution to have consent based GTM
  //implementation due time constraints.
  // Ideally GTM should not be in this file.
  const gtmConsentGiven = useUserCentricsConsent('Google Tag Manager');

  if (!environment.production) {
    return null;
  }

  return (
    <>
      <Script
        type="application/javascript"
        src="https://app.usercentrics.eu/latest/main.js"
        id="ozysu2xCT"
        async
      />
      {gtmConsentGiven && (
        <Script
          strategy="afterInteractive"
          id="gtm-script"
          type="text/javascript"
          data-usercentrics="Google Tag Manager"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MW3D4BQ');`,
          }}
        />
      )}
    </>
  );
};
