// copied for app router from src/components/navbar/Navbar.tsx
// TODO: refactor to be server component

import {
  ContactAssistant,
  Navbar as GrimmeNavbar,
  UserSettingsContent,
  UserSettingsMenu,
} from '@grimme/components';
import { useSession } from '@grimme/next-grimme-auth';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhatsappDialog } from '~/components';
import { Root } from '~/components/navbar/styles';
import { UserGreeting } from '~/components/navbar/user-greeting';
import { UserMenu } from '~/components/navbar/user-menu';
import {
  mapAssistantLinks,
  mapContactLinks,
  mapNavigationLinks,
  mapSocialMediaLinks,
  reformatAssistantData,
  reformatWhatsappNumber,
} from '~/components/navbar/utils';
import { environment } from '~/environments';
import { useContactDetails, useGeolocation } from '~/hooks';
import { useAppDispatch, useAppSelector } from '~/redux';
import { getUser, updateProfileGrid } from '~/redux/auth.slice';
import { GlobalSettings } from '~/utils';
import { login } from '~/utils/auth/login';
import { register } from '~/utils/auth/register';
import { signOut } from '~/utils/auth/signOut';
import { CONTACT_ASSISTANT_COOKIE, DEFAULT_MOBILE_PHONE } from '~/utils/consts';
import { getCookie, setCookie } from '~/utils/cookies';
import languages from '~/utils/data/languages.json';

export type TNavbar = {
  greeting: GlobalSettings['greeting'];
  loggedInMenu: GlobalSettings['logged_in_menu'];
  loggedOutMenu: GlobalSettings['logged_out_menu'];
  more: GlobalSettings['more'];
  navbarData: GlobalSettings['navbar'];
  navigationLinks: GlobalSettings['navigation_menu'];
  socialLinks: GlobalSettings['social_links'];
  topLinks: GlobalSettings['top_links'];
  withoutContactAssistant: boolean;
};

// TODO: This file is just too big and too complex, we should break it down;
export const Navbar = (props: TNavbar) => {
  const {
    greeting,
    loggedInMenu,
    loggedOutMenu,
    more,
    navbarData,
    navigationLinks,
    socialLinks,
    topLinks,
  } = props;
  const { data: contactAssistantData, error } = useContactDetails();
  const pathname = usePathname();
  const params = useParams();
  const router = useRouter();

  const insights = useAppInsightsContext();
  const { status } = useSession();
  const userData = useAppSelector(getUser);

  const isUserLoggedIn = status === 'authenticated';
  const isLoading = status === 'loading' || (isUserLoggedIn && !userData);
  const geolocationData = useGeolocation();
  const userIconRef = useRef<HTMLButtonElement>(null);
  const { i18n, t } = useTranslation();
  const defaultWhatsAppState = Boolean(
    typeof localStorage !== 'undefined' &&
      localStorage.getItem('allowWhatsapp'),
  );
  const shouldShowContactOnMount = Boolean(getCookie(CONTACT_ASSISTANT_COOKIE));
  const whatsappText = t('myGRIMME_website_whatsapp_cta', 'Zum Chat');

  const [whatsAppConsent, setWhatsAppConsent] = useState(defaultWhatsAppState);
  const [showConsentDialog, setShowConsentDialog] = useState<boolean>(false);
  const [isShowingContact, setIsShowingContact] = useState<boolean>(
    shouldShowContactOnMount,
  );
  const [isShowingUserSettings, setIsShowingUserSettings] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const assistantAvatar =
    contactAssistantData?.photo &&
    `data:image/jpeg;base64,${contactAssistantData?.photo}`;
  const assistantLabel = t('myGRIMME_products_contact_assistant', 'Beratung');
  const assistantName = `${contactAssistantData?.firstName} ${contactAssistantData?.lastName}`;
  const contactNumber =
    contactAssistantData?.phone || contactAssistantData?.mobilePhone;
  const contactAssistantEmail = contactAssistantData?.email || ' ';
  const selectWhatsappNumber =
    contactAssistantData?.mobilePhone || DEFAULT_MOBILE_PHONE;
  const whatsappNumber = reformatWhatsappNumber(selectWhatsappNumber);

  const toggleContactAssistant = () => {
    //cookie has to be a string;
    const assistantCookie = !isShowingContact ? 'enabled' : '';
    setIsShowingContact(!isShowingContact);
    setCookie(CONTACT_ASSISTANT_COOKIE, assistantCookie);
  };

  const onEmailClick = () => {
    window.open(`mailto: ${contactAssistantData?.email}`, '_self');
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'email',
        rep: contactAssistantData?.email,
      },
    });
  };

  const { data: sessionData } = useSession();

  // TODO: Whole approach to language sync needs to be done better
  useEffect(() => {
    if (
      status === 'authenticated' &&
      userData?.Language?.toLowerCase() !==
        (params?.locale as string | undefined)?.toLowerCase()
    ) {
      dispatch(
        updateProfileGrid({
          accessToken: sessionData?.accessToken ?? '',
          payload: {
            preferredLanguageCode: (
              params?.locale as string | undefined
            )?.toLowerCase(),
          },
          suppressNotifications: true,
        }),
      );
    }
  }, [status, userData?.Language]);

  const onLanguageChange = async (lng: string) => {
    const pathnameToSet = (pathname || `/${params?.locale}`)?.replace(
      `/${params?.locale}`,
      `/${lng}`,
    );

    router.push(pathnameToSet!);
    router.refresh();
  };

  const onPhoneClick = () => {
    window.open(`tel:${contactNumber}`);
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'phone',
        rep: contactNumber,
      },
    });
  };

  const onWhatsAppClick = () => {
    if (!whatsAppConsent) {
      setShowConsentDialog(true);
    } else {
      window.open(`https://wa.me/${whatsappNumber}`);
      insights?.trackEvent({
        name: 'Contacted Rep',
        properties: {
          method: 'whatsapp',
          rep: whatsappNumber,
        },
      });
    }
  };

  const phoneData = { contactNumber: contactNumber as string, onPhoneClick };
  const emailData = {
    contactAssistantEmail: contactAssistantEmail,
    onEmailClick,
  };
  const whatsappData = {
    onWhatsAppClick,
    whatsappNumber: whatsappNumber as string,
    whatsappText: whatsappText as string,
  };
  const assistantData = reformatAssistantData(
    phoneData,
    emailData,
    whatsappData,
  );
  const assistantLinks = mapAssistantLinks(assistantData);
  const mappedContactLinks = mapContactLinks(assistantData);
  const mappedNavigationLinks = mapNavigationLinks(navigationLinks);
  const mappedSocialLinks = mapSocialMediaLinks(socialLinks);
  const assistantDetails = {
    avatar: assistantAvatar,
    description: assistantLabel,
    name: assistantName,
  };

  const handleOnNavbarLogoClick = () => {
    router.push(environment.grimmeWebsiteUrl);
  };

  const handleOnUserMenuLogoClick = () => {
    router.push('/');
  };

  const handleOnLogout = () => {
    signOut();
  };

  return (
    <Root>
      <GrimmeNavbar
        additionalLinks={navbarData?.additional_links}
        isAuthenticated={isUserLoggedIn}
        links={mappedNavigationLinks}
        onLogoClick={handleOnNavbarLogoClick}
        socialLinks={mappedSocialLinks}
        topLinks={topLinks?.links}
        topLinksLabel={topLinks?.title}
      >
        <UserGreeting
          greeting={greeting}
          more={more}
          ref={userIconRef}
          setIsShowingUserSettings={setIsShowingUserSettings}
        />
        <UserSettingsMenu
          anchorEl={userIconRef.current}
          onBackdropClick={() => setIsShowingUserSettings(false)}
          open={isShowingUserSettings}
        >
          <UserSettingsContent
            MenuComponent={
              <UserMenu
                loggedInMenu={loggedInMenu}
                loggedOutMenu={loggedOutMenu}
                more={more}
              />
            }
            contactLinks={mappedContactLinks}
            contactName={assistantName}
            geolocation={geolocationData}
            isLoading={isLoading}
            isSessionValid={isUserLoggedIn}
            isShowingContact={isShowingContact}
            onClose={() => setIsShowingUserSettings(false)}
            onHideContact={toggleContactAssistant}
            onLanguageChange={onLanguageChange}
            onLogin={() => login()}
            onLogoClick={handleOnUserMenuLogoClick}
            onLogout={handleOnLogout}
            onRegister={() => register(i18n.language)}
            supportedLanguages={languages}
          />
        </UserSettingsMenu>
      </GrimmeNavbar>
      <ContactAssistant
        details={assistantDetails}
        indented
        links={assistantLinks}
        onVisibilityChange={toggleContactAssistant}
        visible={!isShowingContact}
      />
      <WhatsappDialog
        contactNumber={whatsappNumber}
        open={showConsentDialog}
        onCancel={() => {
          setShowConsentDialog(false);
        }}
        onConfirm={() => {
          setWhatsAppConsent(true);
          setShowConsentDialog(false);
        }}
      />
    </Root>
  );
};
