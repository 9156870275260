export const setCookie = async (
  name: string,
  value: string,
  expiryInSeconds?: number,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  const currentDate = new Date();
  const expiryUnitInSeconds = expiryInSeconds || 2592000; // Default is set to 30 days
  currentDate.setTime(currentDate.getTime() + expiryUnitInSeconds * 1000);

  const cookieString = `${name}=${encodeURIComponent(
    value,
  )}; expires=${currentDate.toString()}; path=/`;

  /**
   * We have a cookie getting duplicated cos of subdomain/tld setting.
   * When reading cookie we don't have access to domain data
   * That means we can't pick which cookie to read
   * To avoid further bugs we need to override all cookies having same name, no matter what domain they've been assigned to
   * Carpet bombing override is the only option to make sure all cookie values are in sync across all possible domains
   *
   * Ideally, client side calls should set cookie only to its own domains.
   * TLD cookies should be resolved by server side.
   */

  document.cookie = cookieString;
  /**
   * Also need to set TLD cookie as we already have them set on TST and PROD (previous behavior)
   * Otherwise we'll face the issue of unsynced duplicated cookie reads
   */
  const tld = window.location.hostname.split('.').slice(-2).join('.');
  document.cookie = `${cookieString}; domain=.${tld}; SameSite=lax`;

  let cookieValue = getCookie(name);

  // return only when cookie is set
  while (cookieValue !== value) {
    await new Promise((resolve) => setTimeout(() => resolve(true), 100));

    cookieValue = getCookie(name);
  }
};

export const getCookie = (name: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const allCookies = `; ${document?.cookie}`; //getting all the cookies
  const cookieByName = allCookies?.split(`; ${name}=`); //getting specific cookie by name

  if (cookieByName?.length === 2)
    return cookieByName?.pop()?.split(';').shift(); //returning specific cookie value
};
