import { Button } from '@grimme/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { login } from '../../utils/auth/login';

interface LoginButtonProps {
  classes?: string;
  endIcon?: React.ReactElement;
  modifier?: 'light' | 'dark';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
}

export const LoginButton = (props: LoginButtonProps) => {
  const { classes, endIcon, modifier = 'light', variant = 'primary' } = props;
  const { t } = useTranslation();
  const insights = useAppInsightsContext();

  const handleLogin = React.useCallback(() => {
    insights.trackEvent({ name: 'Login' });
    login();
  }, [insights]);

  return (
    <Button
      className={classes}
      endIcon={endIcon}
      onClick={handleLogin}
      variant={variant}
      modifier={modifier}
    >
      {t('myGRIMME_login', 'Login')}
    </Button>
  );
};
