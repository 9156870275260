import {
  faBan,
  faCircleCheck,
  faCircleX,
  faHourglassHalf,
  faUserCheck,
  faUserClock,
  faUserTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '@grimme/theme';
import { Box, alpha, generateUtilityClasses, styled } from '@mui/material';

export const classes = generateUtilityClasses('Status', [
  'disabled',
  'disabledIcon',
  'error',
  'errorIcon',
  'inactive',
  'inactiveIcon',
  'labelless',
  'pending',
  'pendingIcon',
  'success',
  'successIcon',
  'title',
  'warning',
  'warningIcon',
  'info',
]);

//Colors from new Tailwind library, we do not have them in old, MUI theme,
//once this component is rewritten with tailwind, we can replace them.
const alertRed = '#F2616D';

const baseChipStyling = (background: string, color: string, text?: string) => ({
  [`& svg`]: {
    color: color,
    height: 16,
    width: 16,
  },
  background: background,
  borderColor: color,
  borderStyle: 'solid',
  borderWidth: '1px',
  color: text || color,
  float: 'right',
  fontSize: 14,
  letterSpacing: '0.7px',
  padding: theme.spacing(0.5),
});

const baseIconStyling = (color: string) => ({
  color: color,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.125),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
});

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    [`& .${classes.error}`]: {
      ...baseChipStyling('#f2e1e4', alertRed),
    },
    [`& .${classes.errorIcon}`]: {
      ...baseIconStyling(alertRed),
    },
    [`& .${classes.warning}`]: {
      ...baseChipStyling(
        alpha(theme.palette.warning.main, 0.1),
        theme.palette.warning.main,
        theme.palette.grey[400],
      ),
    },
    [`& .${classes.warningIcon}`]: {
      ...baseIconStyling(theme.palette.warning.main),
    },
    [`& .${classes.disabled}`]: {
      ...baseChipStyling('#f1f6f8', theme.palette.grey[400]),
    },
    [`& .${classes.disabledIcon}`]: {
      ...baseIconStyling(theme.palette.grey[400]),
    },
    [`& .${classes.success}`]: {
      ...baseChipStyling(
        theme.palette.common.white,
        theme.palette.success.main,
      ),
    },
    [`& .${classes.successIcon}`]: {
      ...baseIconStyling(theme.palette.success.main),
    },
    [`& .${classes.inactive}`]: {
      ...baseChipStyling(
        alpha(theme.palette.grey[300], 0.1),
        theme.palette.grey[300],
        theme.palette.grey[400],
      ),
    },
    [`& .${classes.inactiveIcon}`]: {
      ...baseIconStyling(theme.palette.grey[400]),
    },
    [`& .${classes.pending}`]: {
      ...baseChipStyling('#f1f6f8', theme.palette.grey[400]),
    },
    [`& .${classes.pendingIcon}`]: {
      ...baseIconStyling(theme.palette.grey[400]),
    },
    [`& .${classes.info}`]: {
      ...baseChipStyling(
        '#619DF2', // We do not have this color in old MUI theme
        theme.palette.common.white,
        theme.palette.common.white,
      ),
      borderColor: '#619DF2', // We do not have this color in old MUI theme
    },
    [`& .${classes.title}`]: {
      alignContent: 'center',
      alignSelf: 'center',
      color: theme.palette.common.white,
      fontSize: 14,
      letterSpacing: '0.7px',
      marginRight: theme.spacing(1),
    },
    [`& .${classes.labelless}`]: {
      [`& span`]: {
        display: 'none',
      },
      [`& svg`]: {
        color: theme.palette.common.white,
        margin: '0 auto',
        padding: 0,
      },
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
    [`& .${classes.labelless}.${classes.disabled}`]: {
      background: theme.palette.grey[400],
    },
    [`& .${classes.labelless}.${classes.error}`]: {
      background: alertRed,
    },
    [`& .${classes.labelless}.${classes.inactive}`]: {
      [`& svg`]: {
        color: theme.palette.common.white,
      },
      background: theme.palette.grey[300],
    },
    [`& .${classes.labelless}.${classes.success}`]: {
      background: theme.palette.success.main,
    },
    [`& .${classes.labelless}.${classes.warning}`]: {
      background: theme.palette.warning.main,
    },
  };
});

export const AccountErrorAvatar = (
  <FontAwesomeIcon className={classes.errorIcon} icon={faUserTimes} />
);

export const AccountPendingAvatar = (
  <FontAwesomeIcon className={classes.warningIcon} icon={faUserClock} />
);

export const AccountVerifiedAvatar = (
  <FontAwesomeIcon className={classes.successIcon} icon={faUserCheck} />
);

export const MachineVerifiedAvatar = (
  <FontAwesomeIcon className={classes.successIcon} icon={faCircleCheck} />
);
export const MachinePendingAvatar = (
  <FontAwesomeIcon className={classes.warningIcon} icon={faHourglassHalf} />
);
export const MachineInactiveAvatar = (
  <FontAwesomeIcon className={classes.inactiveIcon} icon={faBan} />
);
export const MachineDeclinedAvatar = (
  <FontAwesomeIcon className={classes.errorIcon} icon={faCircleX} />
);
