import { generateUtilityClasses, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('LinkCard', [
  'root',
  'actionArea',
  'card',
  'cardContent',
  'content',
  'disabled',
  'header',
  'icon',
  'link',
  'picture',
  'title',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(0),
    },
  },

  [`&.${classes.disabled}`]: {
    opacity: 0.5,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
    },
  },

  [`& .${classes.card}`]: {
    alignItems: 'center',
    display: 'flex',
    height: theme.spacing(9),
    padding: theme.spacing(3, 2),
    transition: theme.transitions.create(['box-shadow', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      height: 'auto',
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: 'block',
      width: '100%',
    },
    width: theme.spacing(30),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      '$disabled &:hover': {
        transform: 'none',
      },
      '&:hover': {
        transform: 'translate(0, -5px)',
      },
    },
  },

  [`& .${classes.header}`]: {
    alignItems: 'center',
    display: 'flex',
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.common.black,
    //TODO: Figure out a way to apply this to all the icons where its needed
    // backgroundClip: 'text',
    // backgroundImage:
    //   'linear-gradient(314.27deg, #C31924 15.6%, #780009 85.25%)',

    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    minHeight: theme.spacing(3),
    minWidth: theme.spacing(3),
    width: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      height: theme.spacing(2.5),
      marginRight: theme.spacing(1.5),
      width: theme.spacing(2.5),
    },
  },

  [`& .${classes.title}`]: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },

  [`& .${classes.content}`]: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none',
    },
  },
  [`& .${classes.picture}`]: {
    display: 'flex',
  },

  [`& .${classes.actionArea}`]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${classes.cardContent}`]: {
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
    padding: theme.spacing(0),
  },
}));
