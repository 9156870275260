import { Dialog } from '@grimme/components';
import theme from '@grimme/theme';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Box, Checkbox, Link, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface WhatsappDialogProps {
  contactNumber: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}

const TranslationLink = () => {
  return (
    <Link
      href="https://www.grimme.com/privacy/mygrimme"
      target="_blank"
      sx={{ fontWeight: 600, textDecoration: 'none' }}
    />
  );
};

export const WhatsappDialog = (props: WhatsappDialogProps) => {
  const { contactNumber, onCancel, onConfirm, open } = props;

  const [checked, setChecked] = React.useState<boolean>(false);
  const insights = useAppInsightsContext();
  const { t } = useTranslation();

  const defaultKey = 'myGRIMME_products_whatsapp_consent_description';
  const defaultTranslation =
    'Bevor es weitergehen kann: Deine Privatsphäre ist uns wichtig. Unsere Datenschutzbedingungen findest Du <a>hier</a>';
  const labelCancel = t(
    'myGRIMME_products_whatsapp_consent_dialog_cancel',
    'Abbrechen',
  );
  const labelConfirm = t(
    'myGRIMME_products_whatsapp_consent_dialog_confirm',
    'Abschicken',
  );
  const text = t(
    'myGRIMME_products_whatsapp_consent_checkbox_text',
    'Ich habe die Datenschutzbedingungen zur Kenntnis genommen.',
  );
  const title = t('myGRIMME_products_whatsapp_headline', 'Whatsapp');

  const onChange = () => setChecked(!checked);
  const onConfirmHandler = () => {
    localStorage.setItem('allowWhatsapp', Date.now().toFixed(0));
    window.open(`https://wa.me/${contactNumber}`);
    insights?.trackEvent({
      name: 'Contacted Rep',
      properties: {
        method: 'whatsapp',
        rep: contactNumber,
      },
    });
    onConfirm();
  };

  return (
    <Dialog
      confirmButtonProps={{ disabled: !checked }}
      labelCancel={labelCancel}
      labelConfirm={labelConfirm}
      onCancel={onCancel}
      onConfirm={onConfirmHandler}
      open={open}
      title={title}
    >
      <Trans
        i18nKey={defaultKey}
        defaults={defaultTranslation}
        components={{
          a: <TranslationLink />,
        }}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          marginTop: theme.spacing(1),
        }}
      >
        <Checkbox
          data-testid="whatsapp-checkbox"
          checked={checked}
          onChange={onChange}
        />
        <Typography variant="body1">{text}</Typography>
      </Box>
    </Dialog>
  );
};
