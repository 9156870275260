import throttle from 'lodash.throttle';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserTelemetryActiveMachinesSerials } from '~/redux/machines-v2.slice';
import {
  fetchTelemetryData,
  selectTelemetryEntities,
  telemetryStatus,
} from '~/redux/telemetry.slice';
import { loadingStatus } from '~/redux/utils/utils';

export const useTelemetry = () => {
  const [isTelemetryFetched, setIsTelemetryFetched] = useState(false);

  const dispatch = useDispatch();
  const telemetry = useSelector(selectTelemetryEntities);
  const isLoading = useSelector(telemetryStatus) === loadingStatus.LOADING;
  const isLoaded = useSelector(telemetryStatus) === loadingStatus.LOADED;
  const isError = useSelector(telemetryStatus) === loadingStatus.ERROR;
  const activeMachinesSerials = useSelector(selectUserTelemetryActiveMachinesSerials);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTelemetryData = useCallback(
    throttle(
      (accessToken: string, serials?: string[]) => {
        const targetSerials = serials || activeMachinesSerials;

        if (targetSerials?.length) {
          dispatch(
            fetchTelemetryData({
              accessToken,
              serials: targetSerials,
            }),
          );
          setIsTelemetryFetched(true);
        }
      },
      500,
      { leading: true, trailing: false },
    ),
    [dispatch, activeMachinesSerials],
  );

  return {
    fetchTelemetry: getTelemetryData,
    isError,
    isFetched: isTelemetryFetched,
    isLoaded,
    isLoading,
    telemetry,
  };
};
