import { generateUtilityClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses(`Banner`, [
  'root',
  'arrow',
  'arrowIndicatorRegular',
  'backgroundOverlay',
  'backgroundVideo',
  'banner',
  'bannerSubtitleRegular',
  'buttons',
  'buttonScroll',
  'fullHeight',
  'login',
  'registerButtonWrapper',
]);

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
  },
  [`& .${classes.arrow}`]: {
    height: 24,
    padding: theme.spacing(0.25),
    width: 24,
  },
  [`& .${classes.arrowIndicatorRegular}`]: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none',
    },
  },
  [`& .${classes.backgroundOverlay}`]: {
    backgroundImage: 'url(/assets/img/overlay.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    mixBlendMode: 'multiply',
    zIndex: -1,
  },
  [`& .${classes.backgroundVideo}`]: {
    left: 0,
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
    zIndex: -1,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      height: '94vh',
    },
  },
  [`& .${classes.banner}`]: {
    backgroundBlendMode: 'multiply',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    minHeight: '700px',
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      minHeight: '500px',
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
  [`& .${classes.bannerSubtitleRegular}`]: {
    display: 'block',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(2),
    },
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginTop: theme.spacing(4),
    },
  },
  [`& .${classes.buttonScroll}`]: {
    '&:hover, &:focus': {
      color: theme.palette.common.white,
      cursor: 'pointer',
      transform: `translateY(${theme.spacing(1)})`,
    },
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    transition: [
      theme.transitions.create('color'),
      theme.transitions.create('transform'),
    ].join(','),
  },
  [`& .${classes.fullHeight}`]: {
    height: '100%',
    padding: theme.spacing(0),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2.5),
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(1.5),
    },
  },
  [`& .${classes.login}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
    },
  },
  [`& .${classes.registerButtonWrapper}`]: {
    display: 'grid',
    textDecoration: 'none',
  },
}));
