import { notificationsActions } from '@grimme/components';
import { AllInOneProfileData } from '@mygrimme/types';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { GridApiError } from '~/utils/ApiQueryTypes';
import { userGridApi } from '~/utils/rest-apis/grid/api';
import { loadingStatus } from './utils/utils';

export interface AccountsState {
  addStatus: loadingStatus;
  deleteStatus: loadingStatus;
  editStatus: loadingStatus;
  entity?: AllInOneProfileData;
  error?: string;
}

export const ACCOUNTS_FEATURE_KEY = 'accounts';

export const initialState: AccountsState = {
  addStatus: loadingStatus.NOT_LOADED,
  deleteStatus: loadingStatus.NOT_LOADED,
  editStatus: loadingStatus.NOT_LOADED,
  entity: undefined,
  error: undefined,
};

export const createUserAccountGrid = createAsyncThunk(
  'accountsV2/create',
  //Body should not be ANY.
  async (args: { accessToken: string; body: any }, { dispatch }) => {
    try {
      const headers = {
        Authorization: `Bearer ${args.accessToken}`,
      };
      const { data } =
        await userGridApi.companyUsers.currentUserCompanyUsersControllerInviteEmployee(
          {
            email: args.body?.Email,
            firstName: args.body?.FirstName,
            lastName: args.body?.LastName,
          },
          { headers },
        );
      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_create_user_success',
        }),
      );
      return data;
    } catch (error) {
      const apiError = error as GridApiError;
      const defaultErrorKey = 'myGRIMME_core_create_user_failure';
      const errorKeyFromApi = apiError?.response?.data?.error;
      const notificationText = errorKeyFromApi || defaultErrorKey;
      dispatch(
        notificationsActions.addErrorNotification({
          message: notificationText,
        }),
      );
      throw new Error(notificationText);
    }
  },
);

export const deleteUserAccountGrid = createAsyncThunk(
  'accountsV2/delete',
  async (args: { accessToken: string; id: string }, { dispatch }) => {
    try {
      const headers = {
        Authorization: `Bearer ${args.accessToken}`,
      };
      const response =
        await userGridApi.companyUsers.currentUserCompanyUsersControllerDeleteEmployeeAccount(
          args.id,
          { headers },
        );
      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_delete_user_success',
        }),
      );
      return response;
    } catch (error) {
      dispatch(
        notificationsActions.addErrorNotification({
          message: 'myGRIMME_core_delete_user_failure',
        }),
      );
    }
  },
);

export const accountsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserAccountGrid.pending, (state: AccountsState) => {
        state.deleteStatus = loadingStatus.LOADING;
      })
      .addCase(deleteUserAccountGrid.fulfilled, (state: AccountsState) => {
        state.entity = undefined;
        state.error = undefined;
        state.deleteStatus = loadingStatus.LOADED;
      })
      .addCase(
        deleteUserAccountGrid.rejected,
        (state: AccountsState, action) => {
          state.error = action.error.message;
          state.deleteStatus = loadingStatus.ERROR;
        },
      )
      .addCase(createUserAccountGrid.pending, (state: AccountsState) => {
        state.addStatus = loadingStatus.LOADING;
      })
      .addCase(createUserAccountGrid.fulfilled, (state: AccountsState) => {
        state.error = undefined;
        state.addStatus = loadingStatus.LOADED;
      })
      .addCase(
        createUserAccountGrid.rejected,
        (state: AccountsState, action) => {
          state.error = action.error.message;
          state.addStatus = loadingStatus.ERROR;
        },
      )
      .addCase(
        accountsSlice.actions.resetAddAccountState,
        (state: AccountsState) => {
          state.addStatus = loadingStatus.NOT_LOADED;
        },
      )
      .addCase(
        accountsSlice.actions.resetDeleteAccountState,
        (state: AccountsState) => {
          state.deleteStatus = loadingStatus.NOT_LOADED;
        },
      );
  },
  initialState,
  name: ACCOUNTS_FEATURE_KEY,
  reducers: {
    resetAddAccountState: (state) => {
      state.addStatus = loadingStatus.NOT_LOADED;
    },
    resetDeleteAccountState: (state) => {
      state.deleteStatus = loadingStatus.NOT_LOADED;
    },
  },
});

export const accountsReducer = accountsSlice.reducer;

export const accountsActions = accountsSlice.actions;

export const getAccountsState = (rootState: {
  [ACCOUNTS_FEATURE_KEY]: AccountsState;
}): AccountsState => rootState[ACCOUNTS_FEATURE_KEY];

export const isAccountUpdating = createSelector(
  getAccountsState,
  (state) => state.editStatus === loadingStatus.LOADING,
);

export const isAccountUpdated = createSelector(
  getAccountsState,
  (state) => state.editStatus === loadingStatus.LOADED,
);

export const isDeletingAccount = createSelector(
  getAccountsState,
  (state) => state.deleteStatus === loadingStatus.LOADING,
);

export const isAccountDeleted = createSelector(
  getAccountsState,
  (state) => state.deleteStatus === loadingStatus.LOADED,
);

export const isDeletingAccountFailed = createSelector(
  getAccountsState,
  (state) => state.deleteStatus === loadingStatus.ERROR,
);

export const isAccountAdding = createSelector(
  getAccountsState,
  (state) => state.addStatus === loadingStatus.LOADING,
);

export const isAccountAdded = createSelector(
  getAccountsState,
  (state) => state.addStatus === loadingStatus.LOADED,
);

export const isAccountAddingFailed = createSelector(
  getAccountsState,
  (state) => state.addStatus === loadingStatus.ERROR,
);

export const { resetAddAccountState } = accountsSlice.actions;
export const { resetDeleteAccountState } = accountsSlice.actions;
