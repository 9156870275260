//TODO: Either create another slice for single machines or remove this file completely and move to hooks;
import { NotificationMessage, notificationsActions } from '@grimme/components';
import {
  EntityState,
  PayloadAction,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { MachineDto } from '~/gen/grid-user';
import { userGridApi } from '../utils/rest-apis/grid/api';
import { loadingStatus } from './utils/utils';
import { MachineMapper } from './utils/mappers';

export const MACHINES_FEATURE_KEY = 'machinesV2';
export interface MachinesState extends EntityState<MachineDto, string> {
  deleteMachineStatus: loadingStatus;
  error?: string;
  isAddingMachine: boolean;
  isEditingMachine: boolean;
  ladingStatusSingleMachine: loadingStatus;
  loadingStatus: loadingStatus;
  singleMachine?: MachineDto;
}

export const machinesAdapter = createEntityAdapter<MachineDto, string>({
  selectId: (entity) => entity.serialNumber as string,
});

export const fetchMachines = createAsyncThunk(
  'machinesV2/fetch',
  async (args: { accessToken: string }) => {
    const response =
      await userGridApi.machinesApi.currentUserMachinesControllerGetAllMachines(
        {
          headers: {
            Authorization: `Bearer ${args.accessToken}`,
          },
        },
      );

    return response.data;
  },
);

export const fetchSingleMachineGrid = createAsyncThunk(
  'machinesV2/fetchSingleMachineGrid',
  async (args: { accessToken: string; serial: string }) => {
    const response =
      await userGridApi.machinesApi.currentUserMachinesControllerGetMachine(
        args.serial,
        {
          headers: {
            Authorization: `Bearer ${args.accessToken}`,
          },
        },
      );

    return response.data;
  },
);

export const addMachine = createAsyncThunk(
  'machinesV2/addMachine',
  async (
    args: {
      accessToken: string;
      payload: {
        customMachineName: string;
        serialNumber: string;
      };
    },
    { dispatch },
  ) => {
    try {
      const response =
        await userGridApi.machinesApi.currentUserMachinesControllerPostMachine(
          {
            customMachineName: args.payload.customMachineName,
            serialNumber: args.payload.serialNumber,
          },
          {
            headers: {
              Authorization: `Bearer ${args.accessToken}`,
            },
          },
        );

      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_machine_add_success' as NotificationMessage,
        }),
      );

      return response.data;
    } catch (err) {
      dispatch(
        notificationsActions.addErrorNotification({
          message: 'myGRIMME_core_machine_add_failure' as NotificationMessage,
        }),
      );

      throw err;
    }
  },
);

export const deleteMachineGrid = createAsyncThunk(
  'machinesV2/deleteMachineGrid',
  async (
    args: {
      accessToken: string;
      payload: {
        serialNumber: string;
      };
    },
    { dispatch },
  ) => {
    try {
      const response =
        await userGridApi.machinesApi.currentUserMachinesControllerDeleteMachine(
          args.payload.serialNumber,
          {
            headers: {
              Authorization: `Bearer ${args.accessToken}`,
            },
          },
        );

      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_machine_delete_success',
        }),
      );

      return response.data;
    } catch (err) {
      dispatch(
        notificationsActions.addErrorNotification({
          message: 'myGRIMME_core_machine_delete_failure',
        }),
      );

      throw err;
    }
  },
);

export const editMachineGrid = createAsyncThunk(
  'machinesV2/editMachineGrid',
  async (
    args: {
      accessToken: string;
      payload: {
        name: string;
        serialNumber: string;
      };
    },
    { dispatch },
  ) => {
    try {
      const response =
        await userGridApi.machinesApi.currentUserMachinesControllerPatchMachine(
          args.payload.serialNumber,
          { customMachineName: args.payload.name },
          {
            headers: {
              Authorization: `Bearer ${args.accessToken}`,
            },
          },
        );

      dispatch(
        notificationsActions.addSuccessNotification({
          message: 'myGRIMME_core_machine_add_success' as NotificationMessage,
        }),
      );

      return response.data;
    } catch (err) {
      dispatch(
        notificationsActions.addErrorNotification({
          message: 'myGRIMME_core_machine_add_failure' as NotificationMessage,
        }),
      );

      throw err;
    }
  },
);

export const initialMachinesState: MachinesState =
  machinesAdapter.getInitialState({
    ids: [],
    entities: {},
    deleteMachineStatus: loadingStatus.NOT_LOADED,
    error: undefined,
    isAddingMachine: false,
    isEditingMachine: false,
    ladingStatusSingleMachine: loadingStatus.NOT_LOADED,
    loadingStatus: loadingStatus.NOT_LOADED,
    singleMachine: undefined,
  });

export const machinesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachines.pending, (state: MachinesState) => {
        state.loadingStatus = loadingStatus.LOADING;
      })
      .addCase(
        fetchMachines.fulfilled,
        (state: MachinesState, action: PayloadAction<MachineDto[], string>) => {
          machinesAdapter.upsertMany(state, action.payload);
          state.loadingStatus = loadingStatus.LOADED;
        },
      )
      .addCase(fetchMachines.rejected, (state, action) => {
        state.loadingStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      })
      //Single Machine
      .addCase(fetchSingleMachineGrid.pending, (state: MachinesState) => {
        state.ladingStatusSingleMachine = loadingStatus.LOADING;
      })
      .addCase(
        fetchSingleMachineGrid.fulfilled,
        (state: MachinesState, action: PayloadAction<MachineDto, string>) => {
          state.singleMachine = action.payload;
          state.ladingStatusSingleMachine = loadingStatus.LOADED;
        },
      )
      .addCase(fetchSingleMachineGrid.rejected, (state, action) => {
        state.ladingStatusSingleMachine = loadingStatus.ERROR;
        state.error = action.error.message;
      })

      .addCase(addMachine.pending, (state) => {
        state.isAddingMachine = true;
      })
      .addCase(addMachine.fulfilled, (state, action) => {
        state.isAddingMachine = false;

        if (!action.payload) return;

        machinesAdapter.addOne(state, action.payload);
      })
      .addCase(addMachine.rejected, (state) => {
        state.isAddingMachine = false;
      })
      .addCase(editMachineGrid.pending, (state) => {
        state.isEditingMachine = true;
      })
      .addCase(editMachineGrid.fulfilled, (state, action) => {
        state.isEditingMachine = false;

        if (!action.payload) return;

        machinesAdapter.upsertOne(state, action.payload);
      })
      .addCase(editMachineGrid.rejected, (state) => {
        state.isEditingMachine = false;
      })
      .addCase(deleteMachineGrid.pending, (state: MachinesState) => {
        state.deleteMachineStatus = loadingStatus.LOADING;
      })
      .addCase(deleteMachineGrid.fulfilled, (state: MachinesState, action) => {
        machinesAdapter.removeOne(state, action.payload!.serialNumber);
        state.deleteMachineStatus = loadingStatus.LOADED;
        machinesAdapter.removeOne(state, action.payload.serialNumber);
      })
      .addCase(deleteMachineGrid.rejected, (state: MachinesState, action) => {
        state.deleteMachineStatus = loadingStatus.ERROR;
        state.error = action.error.message;
      })
      .addCase(resetDeleteMachineStatus, (state: MachinesState) => {
        state.deleteMachineStatus = loadingStatus.NOT_LOADED;
      });
  },
  initialState: initialMachinesState,
  name: MACHINES_FEATURE_KEY,
  reducers: {
    add: machinesAdapter.addOne,
    remove: machinesAdapter.removeOne,
    resetEditMachineNameStatus: (state) => {
      state.loadingStatus = loadingStatus.NOT_LOADED;
    },
    // ...
  },
});

export const resetDeleteMachineStatus = createAction('machines/redirect/reset');

export const machinesReducer = machinesSlice.reducer;

export const machinesActions = machinesSlice.actions;

export const getMachinesState = (rootState: {
  [MACHINES_FEATURE_KEY]: MachinesState;
}): MachinesState => rootState[MACHINES_FEATURE_KEY];

const { selectAll, selectEntities } = machinesAdapter.getSelectors();

export const selectAllMachines = createSelector(getMachinesState, selectAll);

export const selectMachinesEntities = createSelector(
  getMachinesState,
  selectEntities,
);

export const selectUserMachineBySerial = (serial: string) =>
  createSelector(getMachinesState, (state) => {
    const machines = selectEntities(state);
    const targetMachine = machines[serial];

    return targetMachine
      ? MachineMapper.MapGridToMachineMapping(targetMachine)
      : undefined;
  });

export const selectUserTelemetryActiveMachinesSerials = createSelector(
  getMachinesState,
  (state) => {
    const machines = selectAll(state);

    return (
      machines
        ?.filter((machine) => machine.hasGsc)
        ?.map((m) => m.serialNumber) || []
    );
  },
);

export const selectIsLoadingMachines = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.LOADING,
);

export const selectAreMachinesLoaded = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.LOADED,
);

export const selectAreMachinesFailed = createSelector(
  getMachinesState,
  (state) => state.loadingStatus === loadingStatus.ERROR,
);

export const selectSingleMachine = createSelector(
  getMachinesState,
  (state) => state.singleMachine,
);

export const selectIsAddingMachine = createSelector(
  getMachinesState,
  (state) => state.isAddingMachine,
);

export const selectDeleteMachineStatus = createSelector(
  getMachinesState,
  (state) => state.deleteMachineStatus,
);
