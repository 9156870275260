import { generateUtilityClasses, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const classes = generateUtilityClasses('MachineCarousel', [
  'root',
  'addMachineButton',
  'addMachineIcon',
  'carousel',
  'link',
]);

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(-2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  [`& .${classes.addMachineButton}`]: {
    border: '2px solid white',
    borderRadius: '90%',
    margin: theme.spacing(1, 0, 1, 1),
    padding: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginRight: theme.spacing(-0.75),
    },
  },
  [`& .${classes.addMachineIcon}`]: {
    color: theme.palette.common.white,
    height: theme.spacing(3),
    width: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {},
  },
  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },
}));
export const StyledAddMachineButton = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));
