import { environment } from '~/environments';

class ConnectivityApiServiceClass {
  private getMachineTypeBySerial(serial: string) {
    return `TYP.0${serial.slice(0, 4)}`;
  }

  getMachineImageURLBySerial(serial: string): string {
    const machineType = this.getMachineTypeBySerial(serial);

    return `${environment.connectivityApiUrl}/v3/machines/${machineType}/image`;
  }
}

export const ConnectivityApiService = new ConnectivityApiServiceClass();
